import React from "react";
// import PropTypes from 'prop-types';
// import styles from './StatisticsPage.css';

function StatisticsPage(props) {
  return <div>Statistics</div>;
}

// StatisticsPage.defaultProps = {};

// StatisticsPage.propTypes = {
// };

export default StatisticsPage;
