import AddProductModal from "../Modals/AddProductModal";
import ProductContainer from "./elements/ProductContainer";
import AddProductCategoryModal from "../Modals/AddProductCategoryModal";
import UpdateProductCategoryModal from "../Modals/UpdateProductCategoryModal";
import LoadingState from "../LoadingState/LoadingState";
import useProductCategories from "../../typedHooks/queries/queryProductCategories";

function AdminProducts() {
  const { dataFromQuery: categories } = useProductCategories({});

  return (
    <>
      <div className="px-[16px] py-[30px] mx-auto w-full bg-[#142a41]">
        <div className="flex gap-2 justify-end">
          <AddProductCategoryModal />
        </div>
      </div>
      <div className=" m-auto my-6 px-4 flex flex-wrap justify-between gap-2 gap-y-2">
        {!categories && <LoadingState displayText={"Loading products..."} />}
        {categories?.productcategories?.map((productCategory) => {
          return (
            <div key={productCategory.id} className="py-3 xl:py-4 px-3 xl:px-6 rounded-md border-[1px] bg-white w-full grid grid-cols-12 gap-y-2">
              <div className="flex items-center gap-2">
                <p className="flex-1 text-xl whitespace-nowrap">{productCategory.name}</p>
                <div className="flex-shrink-0">
                  <UpdateProductCategoryModal productCategory={productCategory} />
                </div>
              </div>
              <div className="w-full col-span-12 flex justify-start flex-wrap">
                {productCategory.products.map((product) => {
                  return <ProductContainer key={product.id} product={product} />;
                })}
                <div className="py-2 xl:py-2 rounded-md w-full mr-auto col-span-12">
                  <AddProductModal productCategory={productCategory} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default AdminProducts;
