import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const UPDATE_PRODUCT = gql`
  mutation ProductUpdate($input: ProductUpdateInput!) {
    productupdate(input: $input) {
      ... on Product {
        id
        name
        isActive
        productCategory {
          id
          name
        }
      }
      ... on Error {
        errorCode
        message
      }
    }
  }
`;

const useUpdateProduct = () => {
  const [useUpdateProduct] = useMutation(UPDATE_PRODUCT, { refetchQueries: ["productCategories"] });

  return useUpdateProduct;
};

export default useUpdateProduct;
