import { CircularProgress } from "@material-ui/core";
import { SyncStatus } from "../SyncStatuses";

const ExactSyncHandler = ({ syncStatus, handleSyncClient }: { syncStatus: SyncStatus; handleSyncClient: () => void }) => {
  return (
    <>
      {syncStatus !== SyncStatus.PROCESSING && syncStatus !== SyncStatus.SYNCHRONIZED && (
        <button className="text-white px-2 py-0 rounded-md bg-primary-blue" onClick={handleSyncClient}>
          Sync client
        </button>
      )}
      {syncStatus === SyncStatus.PROCESSING && <CircularProgress size={16} />}
      {syncStatus === SyncStatus.FAILED && <p className="text-red-500 text-xs">client sync failed</p>}
    </>
  );
};

export default ExactSyncHandler;
