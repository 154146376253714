import FormGenerator from "../FormGenerator/FormGenerator";
import CheckboxFilter from "../../interfaces/CheckboxFilter";
import { CircularProgress } from "@material-ui/core";
import { FormEvent, useState } from "react";
import { FormField } from "../../interfaces/FormGeneratorProps";
import UpdateProductFormProps from "../../interfaces/UpdateProductFormProps";
import useCreateProduct from "../../typedHooks/mutations/useCreateProduct";
import useUpdateProduct from "../../typedHooks/mutations/useUpdateProduct";
import useProductFormOptions from "../../typedHooks/queries/queryProductFormOptions";
import { toast } from "react-toastify";

const CreateOrUpdateProductForm = ({ initialValues, closeAfterSubmit, productCategoryId }: UpdateProductFormProps) => {
  const productExists = initialValues ? true : false;
  const { dataFromQuery: productFormOptions } = useProductFormOptions({ take: 512 });
  const createProduct = useCreateProduct();
  const updateProduct = useUpdateProduct();

  const [productName, setProductName] = useState<string | undefined>(initialValues?.name ?? undefined);
  const [selectedProductCategoryId, setProductCategoryId] = useState<string | undefined>(productCategoryId ?? initialValues?.productCategoryId ?? undefined);
  const [selectedExactItemIdExact, setSelectedExactItemIdExact] = useState<string | undefined>(initialValues?.exactItemIdExact ?? undefined);
  const [productIsActive, setProductIsActive] = useState<boolean>(initialValues?.isActive ?? true);
  const checkBoxOptions: CheckboxFilter[] = [{ label: "This product is active", value: "productIsActive", checked: productIsActive }];

  const exactItemOptions: { name: string; id: string }[] =
    productFormOptions?.exactitems && productFormOptions?.exactitems.length > 0
      ? productFormOptions.exactitems
          .filter((exactItem) => exactItem.__typename === "ExactItem")
          .map((exactItem) => ({
            id: "idExact" in exactItem && exactItem.idExact ? exactItem.idExact : "idExact0",
            name: "code" in exactItem && exactItem.code ? `${exactItem.code} ${exactItem.description ? `- ${exactItem.description}` : ""}` : "code0",
          }))
      : [];

  const formFields: FormField[] = [
    {
      label: `Product info: ${productName ?? "New product"}`,
      order: "order-first",
      type: "heading",
    },
    {
      initialValue: productName,
      isRequired: true,
      label: "Product name*",
      onChange: (name: string) => {
        setProductName(name ?? "");
      },
      order: "order-1",
      placeholder: "",
      showDefaultValue: false,
      type: "text",
    },
    {
      initialValue: selectedProductCategoryId,
      isRequired: true,
      label: "Category",
      onChange: (providedCategoryGroupId: string) => {
        setProductCategoryId(providedCategoryGroupId && providedCategoryGroupId !== "0" ? providedCategoryGroupId : undefined);
      },
      options: productFormOptions?.productcategories
        ? productFormOptions?.productcategories.map((clientGroup) => ({ id: String(clientGroup.id), name: clientGroup.name }))
        : [],
      order: "order-1",
      placeholder: "Select an option",
      type: "dropdown",
    },
    {
      initialValue: selectedExactItemIdExact,
      isRequired: false,
      label: "ExactItem",
      onChange: (providedExactItemIdExact: string) => {
        setSelectedExactItemIdExact(providedExactItemIdExact && providedExactItemIdExact !== "0" ? providedExactItemIdExact : undefined);
      },
      options: exactItemOptions,
      order: "order-1",
      placeholder: "Select an option",
      type: "dropdown",
    },
    {
      type: "checkboxes",
      checkboxInitialValue: checkBoxOptions,
      label: "",
      order: "order-1",
      onChange: (value: string) => {
        if (value === "productIsActive") {
          setProductIsActive(!productIsActive);
        }
      },
      // to match options interface
      checkboxOptions: checkBoxOptions,
      additionalStyles: "w-full border-none py-0",
    },
    {
      label: "Submit product info",
      order: "order-3",
      type: "submit",
    },
  ];

  const mutationKeys = (
    productCategoryId: string | undefined,
    id: string | undefined,
    productIsActive: boolean,
    productName: string | undefined,
    selectedExactItemIdExact: string | undefined
  ) => {
    return {
      input: {
        isActive: productIsActive,
        name: productName,
        productCategoryId: Number(productCategoryId),
        ...(id && { id: Number(id) }),
        exactItemIdExact: selectedExactItemIdExact,
      },
    };
  };

  const onSubmitFromParent = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (productExists) {
        await updateProduct({ variables: mutationKeys(selectedProductCategoryId, initialValues?.id, productIsActive, productName, selectedExactItemIdExact) });
        toast.success(`Info for ${productName} has been updated`);
      } else {
        const res = await createProduct({
          variables: mutationKeys(selectedProductCategoryId, undefined, productIsActive, productName, selectedExactItemIdExact),
        });
        if (res?.data?.productcreate?.__typename === "Error") {
          toast.error(`A product named ${productName} already exists.`);
        } else {
          toast.success(`${productName} has been created`);
        }
      }
      if (closeAfterSubmit) {
        closeAfterSubmit();
      }
    } catch (error) {
      toast.error(`Something went wrong in submitting information for ${productName}`);
    }
  };

  if (productFormOptions) {
    return (
      <FormGenerator
        closeFormFunction={closeAfterSubmit}
        customFormStyles={{
          parent:
            "h-full  w-full pb-[80px] border-box flex flex-wrap gap-y-8 bg-white p-5 rounded-xl justify-start md:justify-between lg:bp-[40px] lg:justify-between lg:p-[40px] lg:pb-[40px]",
          children: "w-[100%] md:w-[48.5%]",
        }}
        formFields={formFields}
        layoutOptions={{ hasLabelsOnTop: true, hasCloseButton: true }}
        onSubmitFromParent={onSubmitFromParent}
      />
    );
  }
  return (
    <div className="w-fit h-[120px] bg-white rounded-lg flex flex-wrap justify-center items-center gap-4 p-5">
      <span className="text-center">Loading product data...</span>
      <CircularProgress />
    </div>
  );
};

export default CreateOrUpdateProductForm;
