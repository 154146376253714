import ActivityHub from "../../components/ActivityHub/ActivityHub";
import { ActivityHubFilterProvider } from "../../contexts/ActivityHubContexts";

const HomePage = (props) => {
  return (
    <div className="root">
      <ActivityHubFilterProvider>
        <ActivityHub />
      </ActivityHubFilterProvider>
    </div>
  );
};

export default HomePage;
