import { CheckListItemType, ClientOnBoardingStatus } from "../../codegen/gql/graphql";
import { STATUS_STYLES } from "./constants";
import { onboardingStatusDisplayText } from "../../services/constants";

export default function StatusButton({ statusType, status }: { statusType: CheckListItemType; status: ClientOnBoardingStatus }) {
  const statusStyle = STATUS_STYLES[status];

  return (
    <p
      className={`
      leading-none text-center text-xs px-1 py-1 rounded-xl
      md:text-sm md:w-40
      ${statusStyle}
    `}
    >
      {`${onboardingStatusDisplayText[status]}`}
    </p>
  );
}
