import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import { EUR, GBP, RTFC23NC } from "./constants";
import PlayerCard from "./PlayerCard";
import helpers from "./helpers.js";
import Feed from "./Feed.js";
import moment from "moment";

import olyxbase from "../../services/olyxbase";
import { useQuery } from "react-query";

function LeaderboardPage() {
  const [prices, setPrices] = useState({});
  const stateRef = useRef();
  stateRef.prices = prices;
  const { loading, error, data } = useQuery("prices", olyxbase.getPrices);
  useEffect(() => {
    if (loading) return;
    if (error) {
      console.log(error);
      return;
    }
    setPrices(data);
  }, [loading, error, data]);

  const [distribution, setDistribution] = useState([]);
  const [feed, setFeed] = useState([]);
  const [assetData, setAssetData] = useState({});
  const [aliasMap, setAliasMap] = useState({});
  const [barDataDict, setBarData] = useState({});
  const [thisWeek, setThisWeek] = useState(true);
  const [traderView, setTraderView] = useState(true);
  const [brokerAddresses, setBrokerAddresses] = useState([]);

  // function to toggle modal
  const toggleThisWeek = (bool) => {
    setThisWeek(bool);
  };

  // function to toggle trader/broker view
  const toggleView = (bool) => {
    setTraderView(bool);
  };

  let addresses = [];
  let assets = {};
  let assetPairs = [];

  // 10 seconds
  const refreshInterval = 10000;

  useEffect(
    () => {
      async function fetchData() {
        // pause while useQuery runs
        while (stateRef.prices === undefined || !Object.keys(stateRef.prices).length || !Object.keys(assets).length || !assetPairs.length) {
          await new Promise((r) => setTimeout(r, 500));
        }
        // populate distribution and feed data
        setDistribution(await getDataUpdate(assets, assetPairs));
        setFeed(await getFeedUpdate());
        // refresh
        setInterval(async () => {
          setDistribution(await getDataUpdate(assets, assetPairs));
          setFeed(await getFeedUpdate());
        }, refreshInterval);
      }

      // populate list of participating addresses
      const response = getAddresses();
      response.then(function(item) {
        addresses = item;
      });

      // populate list of broker addresses
      const brokerResponse = getBrokerAddresses();
      brokerResponse.then(function(item) {
        setBrokerAddresses(item);
      });

      // fetch asset data
      const assetResponse = getAssets();
      assetResponse.then(function(item) {
        assets = JSON.parse(item["value"]);
      });

      // fetch asset pairs
      const assetPairsResponse = getAssetPairs();
      assetPairsResponse.then(function(item) {
        assetPairs = JSON.parse(item["value"]);
      });

      fetchData();
    },
    [],
    prices
  );

  const formatPriceData = (data) => {
    // get latest dashboard prices
    const output = data.reduce((result, elem) => {
      result[elem["productId"]] = elem;
      return result;
    }, {});
    return output;
  };

  // get participating addresses
  const getAddresses = async () => {
    const res = await axios.get(`https://node1.crap.exchange/assets/7aAL56rKeYeFYKhYt6hY7megYSMqK2bxr4bejPsUXnP/distribution`);
    // ignore accounts for: Dashboard Bot, GameMaster
    return Object.keys(res.data).filter((item) => !["3L24ZJrqQ2ZCmQQA5o6gK7VfHtuB8oxwkSj", "3KwxggTcUm5CWhfsks5Yv4a3Xb6JXKPR1d5"].includes(item));
  };

  const getBrokerAddresses = async () => {
    const res = await axios.get(`https://node1.crap.exchange/assets/8KDxjysC3Yqad9d3Ukd5suTg78MvJvbYkgPQ21PcmNb7/distribution`);
    return Object.keys(res.data).filter((item) => !["3L24ZJrqQ2ZCmQQA5o6gK7VfHtuB8oxwkSj"].includes(item));
  };

  // get asset data
  const getAssets = async () => {
    const res = await axios.get(`https://node1.crap.exchange/addresses/data/3L24ZJrqQ2ZCmQQA5o6gK7VfHtuB8oxwkSj/asset_data`);
    return res.data;
  };

  // get asset pairs
  const getAssetPairs = async () => {
    const res = await axios.get(`https://node1.crap.exchange/addresses/data/3L24ZJrqQ2ZCmQQA5o6gK7VfHtuB8oxwkSj/asset_pairs`);
    return res.data;
  };

  const getDataUpdate = async (assets, assetPairs) => {
    // format dashboard price data
    const priceData = formatPriceData(stateRef.prices);

    // filter out assetPairs items that are not present in priceData
    const filteredAssetPairs = assetPairs.filter((pair) => {
      const [product] = pair;
      return priceData[assets[product]["id"]];
    });

    // extract prices of interest
    const latestAssetPrices = filteredAssetPairs.reduce((result, pair) => {
      const [product] = pair;
      const item = priceData[assets[product]["id"]];
      if ("bidInt" in item && item["bidInt"]) {
        const decimals = product === RTFC23NC ? item["decimals"] + 2 : item["decimals"];
        result[product] = {
          bid: item["bidInt"] / 10 ** decimals,
          ask: item["askInt"] / 10 ** decimals,
          name: assets[product]["name"],
        };
      } else {
        result[product] = {
          bid: item["priceInt"] / 10 ** item["decimals"],
          ask: item["priceInt"] / 10 ** item["decimals"],
          name: assets[product]["name"],
        };
      }
      return result;
    }, {});

    // get last balance logging date
    const date_res = await axios.get("https://node1.crap.exchange/addresses/data/3L24ZJrqQ2ZCmQQA5o6gK7VfHtuB8oxwkSj/latest_date");
    const date = date_res.data["value"];

    // USD to EUR conversion
    const usdEurConversion = 1 / latestAssetPrices[EUR]["ask"];
    const gbpEurConversion = latestAssetPrices[GBP]["ask"] * usdEurConversion;

    // compute player positions
    let data = {};
    let barData = {};
    let aliasMap = {};
    let positions = [];

    // get addresses that had their balance recently logged
    const loggedAdresses = await axios.get(`https://node1.crap.exchange/addresses/data/3L24ZJrqQ2ZCmQQA5o6gK7VfHtuB8oxwkSj/addresses_${date}`).then((res) => {
      return new Set(JSON.parse(res.data["value"]));
    });

    await Promise.all(
      addresses.map(async (adr) => {
        // get alias
        const aliasResponse = await axios.get("https://node1.crap.exchange/alias/by-address/" + adr);
        let alias = "";
        if (aliasResponse.data.length > 0) {
          alias = aliasResponse.data[0].replace("alias:O:", "");
        }
        aliasMap[adr] = alias;

        data[adr] = {};
        barData[adr] = {};

        // get address balances
        const balance = await helpers.computeBalances(adr, assets, filteredAssetPairs);

        // overall position
        const [overallPos, result, bar] = await helpers.computePosition(
          adr,
          assets,
          filteredAssetPairs,
          latestAssetPrices,
          balance,
          usdEurConversion,
          gbpEurConversion
        );

        // weekly position
        let loggedPos = 0;
        if (loggedAdresses.has(adr)) {
          loggedPos = await axios
            .get(`https://node1.crap.exchange/addresses/data/3L24ZJrqQ2ZCmQQA5o6gK7VfHtuB8oxwkSj/${adr}_${date}`)
            .then((res) => {
              return Number(JSON.parse(res.data["value"]));
            })
            .catch((err) => {
              console.log(err);
            });
        }
        const weeklyPos = overallPos - loggedPos;

        data[adr] = result;
        barData[adr] = bar;
        positions.push({
          address: adr,
          overallPos: overallPos,
          weeklyPos: weeklyPos,
          overallBalance: helpers.formatMoneyKMB(overallPos),
          weeklyBalance: helpers.formatMoneyKMB(weeklyPos),
        });
      })
    );

    aliasMap["3L24ZJrqQ2ZCmQQA5o6gK7VfHtuB8oxwkSj"] = "Dashboard Bot";
    setAliasMap(aliasMap);
    setAssetData(data);
    setBarData(barData);
    return positions;
  };

  const getFeedUpdate = async () => {
    // fetch transactions from all addresses
    const allTransactions = await Promise.all(
      addresses.map(async (adr) => {
        const transactions = await axios.get(`https://node1.crap.exchange/transactions/address/${adr}/limit/100`);
        // only keep transactions corresponding to trades of relevant assets
        const filteredTransactions = transactions.data[0].filter((elem) => elem.type === 7 && elem.order1.assetPair.amountAsset in assets);
        return filteredTransactions;
      })
    );

    const sortedTransactions = allTransactions
      .flat()
      .sort(function(a, b) {
        return b.timestamp - a.timestamp;
      })
      .slice(0, 100);

    let uniqueTransactionIDs = new Set();
    let uniqueTransactions = [];
    sortedTransactions.forEach((elem) => {
      if (!uniqueTransactionIDs.has(elem.id)) {
        uniqueTransactionIDs.add(elem.id);
        uniqueTransactions.push(elem);
      }
    });

    const formattedTransactions = uniqueTransactions.map(function(elem) {
      const assetId = elem.order1.assetPair.amountAsset;
      const product = assets[assetId].name;
      const priceMult = 10 ** 4 / assets[assetId]["price_multiplier"];

      // adjust volume based on number of decimals
      const volume = helpers.formatMoneyKMB(elem.amount / 10 ** assets[assetId]["decimals"]);

      return {
        time: moment(elem.timestamp).fromNow(),
        price: parseFloat(((elem.price / 10 ** 14) * priceMult).toFixed(assets[assetId]["currency_decimals"])),
        product: product,
        volume: volume,
        id: elem.id,
        buyer: elem.order1.sender,
        seller: elem.order2.sender,
        currency_symbol: assets[assetId]["currency_decimals"] === 4 ? "$" : "€",
      };
    });

    return formattedTransactions;
  };

  function sortPositions(positions, key) {
    const copy = [...positions].sort(function(a, b) {
      if (a[key] === b[key]) {
        // sort alphabetically using alias if positions are equal
        if (aliasMap[a.address] < aliasMap[b.address]) {
          return -1;
        } else {
          return 1;
        }
      } else if (b[key] === 0) {
        // b is zero, so 'a' is higher up in leaderboard
        return -1;
      } else if (a[key] === 0) {
        // a is zero, so 'a' is lower in leaderboard
        return 1;
      } else {
        return b[key] - a[key]; // compare two non-zero positions
      }
    });
    return copy;
  }

  const fromColor = ["from-amber-500", "from-zinc-400", "from-[#AA7337]"].concat(Array(30).fill("from-white"));
  const toColor = ["to-amber-500", "to-zinc-400", "to-[#AA7337]"].concat(Array(30).fill("to-white"));
  const viaColor = ["via-amber-300", "via-zinc-300", "via-[#CE9D68]"].concat(Array(30).fill("via-white"));
  const ranks = ["🏆", "🥈", "🥉"].concat(Array.from({ length: 30 }, (_, i) => i + 4 + "."));
  const visibilities = ["visible", "visible", "visible"].concat(Array(30).fill("hidden"));

  if (distribution !== "undefined") {
    // construct separate broker and trader distributions
    let brokerDistribution = distribution.filter(({ address }) => brokerAddresses.includes(address));
    let traderDistribution = distribution.filter(({ address }) => !brokerAddresses.includes(address));

    return (
      <div className="row">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="column flex-[1_1_50%] mx-8">
            <div className="grid grid-cols-12 mt-5">
              <div className="col-span-4"> </div>
              <div className="col-span-4">
                <select
                  id="ranking_view"
                  onChange={() => toggleView(!traderView)}
                  className="text-center w-full bg-white border border-gray-300 text-gray-900 text-2xl rounded-lg p-2.5 drop-shadow-md focus:outline-none"
                >
                  <option defaultValue="traders">Traders</option>
                  <option value="brokers">Brokers</option>
                </select>
              </div>
              <div className="col-span-2 table">
                <div className="table-cell align-middle text-center">
                  <button onClick={() => toggleThisWeek(true)}>
                    <h1 className={`text-2xl  ${thisWeek ? "underline-offset-8 underline" : "text-zinc-500"}`}>Weekly</h1>
                  </button>
                </div>
              </div>
              <div className="col-span-2 table">
                <div className="table-cell align-middle text-center">
                  <button onClick={() => toggleThisWeek(false)}>
                    <h1 className={`text-2xl  ${thisWeek ? "text-zinc-500" : "underline-offset-8 underline"}`}>Overall</h1>
                  </button>
                </div>
              </div>
            </div>
            <div className={`items-center justify-center text-gray-700 mb-5 ${thisWeek && traderView ? "visible" : "hidden"}`}>
              {sortPositions(traderDistribution, "weeklyPos").map((acct, i) => (
                <PlayerCard
                  key={acct.address}
                  address={acct.address}
                  overallBalance={acct.overallBalance}
                  weeklyBalance={acct.weeklyBalance}
                  alias={aliasMap[acct.address]}
                  rank={ranks[i]}
                  visibility={visibilities[i]}
                  fromColor={fromColor[i]}
                  toColor={toColor[i]}
                  viaColor={viaColor[i]}
                  assetData={assetData[acct.address]}
                  barData={barDataDict[acct.address]}
                  weekly={thisWeek}
                />
              ))}
            </div>
            <div className={`items-center justify-center text-gray-700 mb-5 ${!thisWeek && traderView ? "visible" : "hidden"}`}>
              {sortPositions(traderDistribution, "overallPos").map((acct, i) => (
                <PlayerCard
                  key={acct.address}
                  address={acct.address}
                  overallBalance={acct.overallBalance}
                  weeklyBalance={acct.weeklyBalance}
                  alias={aliasMap[acct.address]}
                  rank={ranks[i]}
                  visibility={visibilities[i]}
                  fromColor={fromColor[i]}
                  toColor={toColor[i]}
                  viaColor={viaColor[i]}
                  assetData={assetData[acct.address]}
                  barData={barDataDict[acct.address]}
                  weekly={thisWeek}
                />
              ))}
            </div>

            <div className={`items-center justify-center text-gray-700 mb-5 ${thisWeek && !traderView ? "visible" : "hidden"}`}>
              {sortPositions(brokerDistribution, "weeklyPos").map((acct, i) => (
                <PlayerCard
                  key={acct.address}
                  address={acct.address}
                  overallBalance={acct.overallBalance}
                  weeklyBalance={acct.weeklyBalance}
                  alias={aliasMap[acct.address]}
                  rank={ranks[i]}
                  visibility={visibilities[i]}
                  fromColor={fromColor[i]}
                  toColor={toColor[i]}
                  viaColor={viaColor[i]}
                  assetData={assetData[acct.address]}
                  barData={barDataDict[acct.address]}
                  weekly={thisWeek}
                />
              ))}
            </div>
            <div className={`items-center justify-center text-gray-700 mb-5 ${!thisWeek && !traderView ? "visible" : "hidden"}`}>
              {sortPositions(brokerDistribution, "overallPos").map((acct, i) => (
                <PlayerCard
                  key={acct.address}
                  address={acct.address}
                  overallBalance={acct.overallBalance}
                  weeklyBalance={acct.weeklyBalance}
                  alias={aliasMap[acct.address]}
                  rank={ranks[i]}
                  visibility={visibilities[i]}
                  fromColor={fromColor[i]}
                  toColor={toColor[i]}
                  viaColor={viaColor[i]}
                  assetData={assetData[acct.address]}
                  barData={barDataDict[acct.address]}
                  weekly={thisWeek}
                />
              ))}
            </div>
          </div>
          <div className="column flex-[1_1_50%] mx-8">
            <Feed aliasMap={aliasMap} feed={feed} className="sticky"></Feed>
          </div>
        </div>
      </div>
    );
  } else {
    return;
  }
}

export default LeaderboardPage;
