import React from "react";
import { useForm } from "react-hook-form";
import { RegularInputContext } from "../FilterComponents/RegularInput/RegularInputContext";
import { ProductCategoryFormData } from "./ProductCategoryForm.schema";
import { productCategoryResolver } from "./ProductCategoryForm.resolver";
import { useProductCategoryFormSubmit } from "./useProductCategoryFormSubmit";

interface ProductCategoryFormProps {
  initialData?: ProductCategoryFormData;
  closeAfterSubmit?: () => void;
  onSubmitSuccess?: () => void;
  onClose?: () => void;
}

export default function ProductCategoryForm({ initialData, closeAfterSubmit, onSubmitSuccess, onClose }: ProductCategoryFormProps) {
  const {
    control,
    handleSubmit: handleFormSubmit,
    formState: { isValid, isDirty },
  } = useForm<ProductCategoryFormData>({
    defaultValues: initialData || {
      name: "",
    },
    mode: "onChange",
    resolver: productCategoryResolver,
  });

  const { handleSubmit, isLoading, error } = useProductCategoryFormSubmit({
    closeAfterSubmit,
    onSubmitSuccess,
    initialData: initialData as { id: string } & ProductCategoryFormData,
  });

  const isSubmitDisabled = isLoading || !isValid || !isDirty;

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold">{initialData ? "Update Product Category" : "Create New Product Category"}</h2>
      </div>

      <form onSubmit={handleFormSubmit(handleSubmit)}>
        <div className="flex items-start gap-4">
          <div className="flex-1">
            <RegularInputContext label="Product Category Name" name="name" control={control} isRequired />
            {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
          </div>

          <button
            type="submit"
            disabled={isSubmitDisabled}
            className="px-4 py-2 bg-blue-400 text-white rounded whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-500 mt-1"
          >
            {isLoading ? "Processing..." : initialData ? "Update Product Category" : "Create Product Category"}
          </button>
        </div>
      </form>
    </div>
  );
}
