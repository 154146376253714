import { CircularProgress } from "@material-ui/core";

const LoadingState = ({ displayText }: { displayText: string }) => {
  return (
    <div className="w-full h-[64px] bg-white rounded-lg flex flex-wrap items-center gap-4 p-1 mx-auto">
      <span className="text-center">{displayText}</span>
      <CircularProgress />
    </div>
  );
};

export default LoadingState;
