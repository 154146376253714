import { graphql } from "../../codegen/gql";
import { GetCrmOptionsQuery } from "../../codegen/gql/graphql";
import useGeneratedTypeQuery from "../helpers/useGeneratedTypeQuery";

const GET_CRM_FILTER_OPTIONS = graphql(`
  query getCrmOptions {
    brokers {
      id
      brokerName
    }
    products {
      id
      name
    }
  }
`);

const useCrmFilterOptions = () => {
  const { dataFromQuery } = useGeneratedTypeQuery<GetCrmOptionsQuery>(GET_CRM_FILTER_OPTIONS, {});
  return { dataFromQuery };
};

export default useCrmFilterOptions;
