import { useState, useCallback } from "react";

const useGenericPagination = (totalCount: number, take: number) => {
  const [skip, setSkip] = useState(0);

  const navigateForward = useCallback(() => {
    setSkip((prevSkip) => Math.min(prevSkip + take, totalCount - take));
  }, [take, totalCount]);

  const navigateBack = useCallback(() => {
    setSkip((prevSkip) => Math.max(prevSkip - take, 0));
  }, [take]);

  const from = skip + 1;
  const to = Math.min(skip + take, totalCount);

  return {
    skip,
    navigateForward,
    navigateBack,
    from,
    to,
  };
};

export default useGenericPagination;
