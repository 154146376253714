import { useEffect, useState } from "react";
import SearchField from "../../../FilterComponents/SearchField/SearchField";
import AutoCompleteDropdown from "../../../FilterComponents/AutoCompleteDropdown/AutoCompleteDropdown";
import useCrmFilterOptions from "../../../../typedHooks/queries/queryCrmFilterOptions";
import { Broker, Product } from "../../../../codegen/gql/graphql";
import { useUser } from "../../../../typedHooks/useUser";
const ContactTableFilters = ({
  searchText,
  setSearchText,
  setBrokerIds,
  setProductIds,
}: {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setBrokerIds: React.Dispatch<React.SetStateAction<number[]>>;
  setProductIds: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
  const { localUser } = useUser();
  const { dataFromQuery } = useCrmFilterOptions();

  const [TextValue, setTextValue] = useState(searchText);
  const [userView, setUserView] = useState(0);
  const [productView, setProductView] = useState(0);

  useEffect(() => {
    setSearchText(TextValue);
  }, [setSearchText, TextValue]);

  useEffect(() => {
    setBrokerIds(userView === 0 ? [] : [userView]);
  }, [setBrokerIds, userView]);

  useEffect(() => {
    setProductIds(productView === 0 ? [] : [productView]);
  }, [setProductIds, productView]);

  return (
    <form className="flex flex-wrap pag-2 md:gap-4 w-full xl:w-3/4 py-4">
      <SearchField value={TextValue} setValue={setTextValue} onChange={() => {}}></SearchField>
      <AutoCompleteDropdown
        customFormStyles="w-full py-[10px] pt-[24px] md:p-[0px] md:w-[25%] md:pt-[0px] "
        hasLabelsOnTop={false}
        initialValue="0"
        label="Product"
        onChange={(productId: number) => {
          setProductView(productId ? Number(productId) : 0);
        }}
        options={
          dataFromQuery && dataFromQuery.products
            ? dataFromQuery.products.map((product: Product) => ({ id: String(product.id), name: product.name ?? "Unknown" }))
            : []
        }
        order="order-1"
        placeholder="All"
        additionalStyles=""
      ></AutoCompleteDropdown>
      <AutoCompleteDropdown
        customFormStyles="w-full py-[10px] pt-[24px] md:p-[0px] md:w-[25%] md:pt-[0px] "
        hasLabelsOnTop={false}
        initialValue={String(localUser?.id ?? 0)}
        label="Broker"
        onChange={(userId: number) => {
          setUserView(userId ? Number(userId) : 0);
        }}
        options={dataFromQuery ? dataFromQuery.brokers.map((broker: Broker) => ({ id: String(broker.id), name: broker.brokerName })) : []}
        order="order-1"
        placeholder="All"
        additionalStyles=""
      />
    </form>
  );
};

export default ContactTableFilters;
