import SearchIcon from "@mui/icons-material/Search";
import { Dispatch, SetStateAction } from "react";

const SearchField = ({
  value,
  setValue,
  onChange,
}: {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="w-full md:w-[33%] xl:w-[40%] px-3 py-2 border border-gray-200 rounded-xl focus:outline-none focus:ring focus:ring-primary-blue focus:border-primary-blue bg-white flex items-center">
      <SearchIcon></SearchIcon>
      <input
        type="text"
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
          onChange(event);
        }}
        className="bg-white w-full outline-none"
      />
    </div>
  );
};

export default SearchField;
