import { usePermissions } from "../../typedHooks/usePermissions";
import helpers from "../../services/helpers";

const RoleDisplay = () => {
  const { userRole } = usePermissions();
  const userRoleDisplayText = helpers.getUserRoleDisplayText(userRole);

  return (
    <div>
      <p className="text-primary-blue text-xs rounded-md px-2 py-1 bg-white bg-opacity-20">{userRoleDisplayText}</p>
    </div>
  );
};

export default RoleDisplay;
