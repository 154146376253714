import { useState } from "react";
import { ContactType } from "../../../codegen/gql/graphql";
import ContactTableHead from "../../../components/ContactTable/ContactTableHead";
import ContactsFilterButtons from "./ContactsFilterButtons";
import EmptyState from "../../../components/EmptyState";
import ContactTableRow from "../../../components/ContactTable/ContactTableRow";
import helpers from "../../../services/helpers";
import { IClient, ICompanyContact } from "../../../services/utils/interfaces/ClientDisplayTypes";

const CompanyDetailsContactsContainer = ({ clientData }: { clientData: IClient }) => {
  const [contactTypeFilter, setContactTableFilters] = useState<ContactType[]>([ContactType.Sales]);
  const [showArchivedContacts, setShowArchivedContacts] = useState(false);

  const companyAndContactData: ICompanyContact[] | undefined = clientData ? clientData.companyContact : undefined;

  const filteredDataByContactType = companyAndContactData?.filter(({ contactType }) => contactTypeFilter.includes(contactType)) || [];
  const filteredDataByArchived = filteredDataByContactType.filter(({ isArchived }) => showArchivedContacts === isArchived);

  const gridColumns = helpers.getCrmGridColumns(contactTypeFilter, true);

  return (
    <div className="w-full flex p-4 rounded-xl bg-white justify-between flex-wrap items-start mt-[48px]">
      <div className="flex gap-2 flex-wrap w-full md:w-40">
        <h2 className="w-full text-xl font-medium">Contacts</h2>
        <ContactsFilterButtons
          contactTypeFilter={contactTypeFilter}
          setContactTableFilters={setContactTableFilters}
          showArchivedContacts={showArchivedContacts}
          setShowArchivedContacts={setShowArchivedContacts}
        />
      </div>
      <div className="w-full md:w-[calc(100%-176px)] mt-4 sm:mt-0 sm:p-4 rounded-[16px]">
        {filteredDataByArchived?.length ? (
          <>
            <div className="w-full overflow-x-auto scrollbar-custom">
              <div className={`min-w-[600px] md:min-w-[1327px] grid grid-cols-8 font-medium text-[12px] text-[#AFAFAF] ${gridColumns}`}>
                <ContactTableHead contactTypeFilter={contactTypeFilter} hideCompanyName={true} />
              </div>
              <div className="bg-[#fbfbfb] rounded-[16px] p-4 min-w-[600px] md:min-w-[1327px]">
                {filteredDataByArchived.map((row, index) => (
                  <ContactTableRow
                    key={row.id}
                    row={row}
                    isLast={index === filteredDataByArchived.length - 1}
                    hideCompanyName={true}
                    contactTypeFilter={contactTypeFilter}
                  />
                ))}
              </div>
            </div>
          </>
        ) : (
          <EmptyState title="No contacts found" />
        )}
      </div>
    </div>
  );
};

export default CompanyDetailsContactsContainer;
