import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ContactType, OrderByDirection, OrderCompanyContactByField } from "../../codegen/gql/graphql";
interface SortConfig {
  key: OrderCompanyContactByField;
  direction: OrderByDirection.Asc | OrderByDirection.Desc;
}

const ContactTableHead = ({
  handleSort,
  sortConfig,
  contactTypeFilter,
  hideCompanyName,
}: {
  handleSort?: (key: OrderCompanyContactByField) => void;
  sortConfig?: SortConfig;
  contactTypeFilter: ContactType[];
  hideCompanyName: boolean;
}) => {
  return (
    <>
      {!hideCompanyName && (
        <div
          onClick={handleSort ? () => handleSort(OrderCompanyContactByField.CompanyName) : undefined}
          className={`px-2 col-span-2 ${sortConfig ? "hover:bg-primary-blue/10 cursor-pointer" : ""} rounded-md transition-all flex items-center`}
        >
          Company name
          {sortConfig?.key === "companyName" &&
            (sortConfig.direction === "ASC" ? (
              <KeyboardArrowDownIcon className="hidden md:inline-block" />
            ) : (
              <KeyboardArrowUpIcon className="hidden md:inline-block" />
            ))}
        </div>
      )}
      <div
        onClick={handleSort ? () => handleSort(OrderCompanyContactByField.ContactName) : undefined}
        className={`px-2 col-span-2 ${sortConfig ? "hover:bg-primary-blue/10 cursor-pointer" : ""} rounded-md transition-all flex items-center`}
      >
        Contact name
        {sortConfig?.key === "contactName" &&
          (sortConfig?.direction === "ASC" ? (
            <KeyboardArrowDownIcon className="hidden md:inline-block" />
          ) : (
            <KeyboardArrowUpIcon className="hidden md:inline-block" />
          ))}
      </div>
      <div className="px-2  col-span-3 hidden md:flex items-center">Email</div>
      <div className="px-2  col-span-2 flex items-center ">Phone</div>
      {contactTypeFilter.length > 0 && contactTypeFilter[0] === ContactType.Sales && (
        <>
          <div className="px-2  col-span-2 hidden md:flex  items-center">Product(s)</div>
          <div className="px-2  col-span-1 hidden md:flex  items-center">Broker(s)</div>
        </>
      )}
      <div
        onClick={handleSort ? () => handleSort(OrderCompanyContactByField.LastContactedAt) : undefined}
        className={`px-2  col-span-2 ${sortConfig ? "hover:bg-primary-blue/10 cursor-pointer" : ""} rounded-md transition-all flex items-center`}
      >
        Contacted by
        {sortConfig?.key === "lastContactedAt" &&
          (sortConfig?.direction === "ASC" ? (
            <KeyboardArrowDownIcon className="hidden md:inline-block" />
          ) : (
            <KeyboardArrowUpIcon className="hidden md:inline-block" />
          ))}
      </div>
    </>
  );
};

export default ContactTableHead;
