import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomeContent from "../components/HomeContent";
import UserContent from "../components/UserContent";
import NotFoundContent from "../components/NotFoundContent";
import Dashboard from "../pages/Dashboard";
import AddDealPage from "../pages/AddDealPage";
import ClientPage from "../pages/ClientPage";
import SandboxPage from "../pages/SandboxPage/SandboxPage";
import SpreadVisualiserPage from "../pages/SpreadVisualiserPage";
import StatisticsPage from "../pages/StatisticsPage/StatisticsPage";
import TimelinePage from "../pages/TimelinePage";
import FeedbackPage from "../pages/FeedbackPage";
import HomePage from "../pages/HomePage";
import MaintenancePage from "../pages/MaintenancePage";
import MyDealsPage from "../pages/MyDealsPage";
import ProductHistoryPage from "../pages/ProductHistoryPage/ProductHistoryPage";
import MyDealsDetailPage from "../pages/MyDealsDetailPage/MyDealsDetailPage";
import LeaderboardPage from "../pages/LeaderboardPage/LeaderboardPage";
import ExternalLinksPage from "../pages/ExternalLinksPage/ExternalLinksPage";
import DealManagementPage from "../pages/DealManagementPage/";
import NetworkGraphPage from "../pages/NetworkGraphPage/NetworkGraphPage";
import ReportingPage from "../pages/ReportingPage/ReportingPage";
import AdminContent from "../components/AdminContent";
import AdminPage from "../pages/AdminPage";
import AddDealExtensivePage from "../pages/AddDealExtensivePage";
import InvoicePage from "../pages/InvoicePage/";
import WorkloadReportingPage from "../pages/WorkloadReportingPage/WorkloadReportingPage";
import { useUser } from "../typedHooks/useUser";
import AdminRouteGuard from "./RouteGuards/AdminRouteGuard";
import RegularRouteGuard from "./RouteGuards/RegularRouteGuard";
import ManagerRouteGuard from "./RouteGuards/ManagerRouteGuard";
import { ROUTES } from "../services/constants";
import EngineerRouteGuard from "./RouteGuards/EngineerRouteGuard";
import CrmPage from "../pages/CrmPage/CrmPage";
import CompanyDetailsPage from "../pages/CompanyDetailsPage/CompanyDetailsPage";


const Router = ({ bar, openSnackbar, config }) => {
  const { localUser } = useUser();

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      {bar}
      <Routes>
        <Route path={ROUTES.HOME} element={localUser ? <HomePage /> : <HomeContent user={localUser} openSnackbar={openSnackbar} />} />
        
        <Route element={<RegularRouteGuard />}>
          {process.env.REACT_APP_IS_MAINTENANCE === "true" && <Route path={ROUTES.NOT_FOUND} element={<MaintenancePage />} />}
          <Route path={ROUTES.NETWORK_GRAPH} element={<NetworkGraphPage />} />
          <Route path={ROUTES.LOCAL_USER} element={<UserContent />} />
          <Route path={ROUTES.DASHBOARD} element={<Dashboard config={config} />} />
          <Route path={ROUTES.PRODUCT_HISTORY} element={<ProductHistoryPage />} />
          <Route path={ROUTES.TIMELINE} element={<TimelinePage />} />
          <Route path={ROUTES.ADD_DEAL} element={<AddDealPage />} />
          <Route path={ROUTES.CLIENTS} element={<ClientPage />} />
          <Route path={ROUTES.FEEDBACK} element={<FeedbackPage />} />
          <Route path={ROUTES.SANDBOX} element={<SandboxPage />} />
          <Route path={ROUTES.SPREAD_VISUALISER} element={<SpreadVisualiserPage />} />
          <Route path={ROUTES.EXTERNAL_LINKS} element={<ExternalLinksPage />} />
          <Route path={ROUTES.REPORTING} element={<ReportingPage />} />
          <Route path={ROUTES.STATISTICS} element={<StatisticsPage />} />
          <Route path={ROUTES.MY_DEALS_DETAIL} element={<MyDealsDetailPage />} />
          <Route path={ROUTES.MY_DEALS} element={<MyDealsPage />} />
          <Route path={ROUTES.LEADERBOARD} element={<LeaderboardPage />} />
          <Route path={ROUTES.DEAL_MANAGEMENT} element={<DealManagementPage />} />
        </Route>
        
        <Route element={<AdminRouteGuard />}>
          <Route path={ROUTES.ADMIN_PAGE} element={<AdminPage />} />
          <Route path={ROUTES.ADMIN} element={<AdminContent />} />
          <Route path={ROUTES.INVOICES} element={<InvoicePage />} />
          <Route path={ROUTES.ADD_DEAL_EXTENSIVE} element={<AddDealExtensivePage />} />
        </Route>

        <Route element={<ManagerRouteGuard />}>
          <Route path={ROUTES.WORKLOAD} element={<WorkloadReportingPage />} />
        </Route>

        <Route element={<EngineerRouteGuard />}>
          <Route path={ROUTES.CRM_DETAIL} element={<CompanyDetailsPage />} />
          <Route path={ROUTES.CRM} element={<CrmPage />} />
        </Route>
        
        <Route path={ROUTES.NOT_FOUND} element={<NotFoundContent />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;