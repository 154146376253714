import { AnimatePresence, motion, Variants } from "framer-motion";
import { useEffect, useState } from "react";
import { CheckListItemActionStatus, DealCondition, DealConfirmation } from "../../../codegen/gql/graphql";
import { useActivityHubFilter } from "../../../contexts/ActivityHubContexts";
import useDealConfirmations from "../../../typedHooks/queries/queryDealConfirmations";
import { addDays } from "date-fns";
import useDealConditions from "../../../typedHooks/queries/queryDealConditions";
import useMarkDealConditionAsConfirmed from "../../../typedHooks/mutations/useMarkDealConditionAsConfirmed";
import useMarkDealConfirmationAsConfirmed from "../../../typedHooks/mutations/useMarkDealConfirmationAsConfirmed";
import useCheckListItems from "../../../typedHooks/queries/queryCheckListItems";
import ChecklistItem from "../../ChecklistItem/ChecklistItem";
import { CheckListItemType } from "../../../codegen/gql/graphql";
import { CheckListItem } from "../../../interfaces/CheckListItems";
import useMarkCheckListItemActionAsDone from "../../../typedHooks/mutations/useMarkCheckListItemAsDone";

const ChecklistItemContainer = ({ column }: { column: string }) => {
  const { selectedUserViewInArray, checklistView } = useActivityHubFilter();
  const { dataFromQuery: dealConfirmationsFromQuery, refetch: refetchConfirmations } = useDealConfirmations({
    userIds: selectedUserViewInArray,
    isConfirmed: column === "Done",
  });

  const [oneWeekFromNow, setOneWeekFromNow] = useState(addDays(new Date(), 7));
  const { dataFromQuery: dealConditionsFromQuery, refetch: refetchDealConditions } = useDealConditions({
    isConfirmed: column === "Done",
    userIds: selectedUserViewInArray,
    toBeResolvedBefore: oneWeekFromNow,
  });

  const { dataFromQuery: checkListItemsFromQuery, refetch: refetchCheckListItems } = useCheckListItems({
    checkListItemTypes: [CheckListItemType.Clientonboarding, CheckListItemType.Clientkyc],
    take: 100,
    status: column === "Done" ? CheckListItemActionStatus.Done : CheckListItemActionStatus.Todo,
    ...(column === "Todo" && { toBeResolvedBefore: oneWeekFromNow }),
    ...(column === "Pending" && { toBeResolvedAfter: oneWeekFromNow }),
    userIds: selectedUserViewInArray,
  });

  useEffect(() => {
    refetchConfirmations();
    refetchDealConditions();
    refetchCheckListItems();
  }, [checklistView, selectedUserViewInArray]);

  const dealConfirmations = dealConfirmationsFromQuery?.dealconfirmations as DealConfirmation[] | undefined;
  const dealConditions = dealConditionsFromQuery?.dealconditions as DealCondition[] | undefined;
  const checkListItems = checkListItemsFromQuery?.checklistitems as CheckListItem[] | undefined;

  // TODO: when confirmations and conditions are included in CheckListItems creating a combined array is not necessary
  const combinedArray = [...(column !== "Pending" ? dealConditions ?? [] : []), ...(checkListItems ?? []), ...(dealConfirmations ?? [])];

  // TODO: when confirmations and conditions are included in CheckListItems we want to sort by checklistItemType
  const sortedCombinedArray = [...combinedArray].sort((a, b) => {
    if (a.updatedAt && b.updatedAt) {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    }
    return 0;
  });

  const markDealConditionAsConfirmed = useMarkDealConditionAsConfirmed();
  const markDealConfirmationAsConfirmed = useMarkDealConfirmationAsConfirmed();
  const markCheckListItemActionAsDone = useMarkCheckListItemActionAsDone();

  const handleConfirmCondition = (id: string) => {
    markDealConditionAsConfirmed({
      variables: {
        input: {
          dealConditionId: parseInt(id, 10),
        },
      },
    });
  };

  const handleConfirmConfirmation = (id: string) => {
    markDealConfirmationAsConfirmed({
      variables: {
        input: {
          dealConfirmationId: parseInt(id, 10),
        },
      },
    });
  };

  const handleCompleteCheckListItemAction = (id: string) => {
    markCheckListItemActionAsDone({
      variables: {
        input: {
          checkListItemActionId: parseInt(id, 10),
        },
      },
    });
  };

  const container: Variants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.05,
      },
    },
  };

  const item: Variants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <>
      <motion.div
        className="mt-3 flex flex-wrap gap-y-[16px]"
        variants={container}
        initial="hidden"
        animate={combinedArray && combinedArray.length > 0 ? "visible" : "hidden"}
        exit="hidden"
      >
        {sortedCombinedArray &&
          sortedCombinedArray.length > 0 &&
          combinedArray &&
          checkListItems &&
          (column === "Done" ? sortedCombinedArray : column === "Todo" ? combinedArray : checkListItems).map((checklistItem, index) => {
            const isDealCondition = checklistItem.__typename === "DealCondition";
            const isCheckListItem = checklistItem.__typename === "CheckListItem";
            // TODO: we can directly call handleCompleteCheckListItemAction once the new CheckListItems are implemented
            const thisItemsConfirmationMutation = isCheckListItem
              ? () => handleCompleteCheckListItemAction(checklistItem.currentCheckListItemAction.id)
              : isDealCondition
              ? () => handleConfirmCondition(checklistItem.id)
              : () => handleConfirmConfirmation(checklistItem.id);

            return (
              <AnimatePresence>
                <motion.div className="w-full" variants={item} key={checklistItem.id}>
                  {checklistItem && (
                    <ChecklistItem
                      checklistItem={checklistItem}
                      olyxRef={checklistItem.deal ? checklistItem.deal.olyxRef.toString() : undefined}
                      mutation={thisItemsConfirmationMutation}
                      // TODO: next line, to be removed when new CheckListItems are implemented
                      isCompleted={column === "Done"}
                    ></ChecklistItem>
                  )}
                </motion.div>
              </AnimatePresence>
            );
          })}
      </motion.div>
      {sortedCombinedArray.length === 0 && (
        <div className="bg-gray-100 px-4 py-8 rounded-xl text-center w-full mt-4">
          <span className="text-lg text-center text-gray-400">No checklist items</span>
        </div>
      )}
    </>
  );
};

export default ChecklistItemContainer;
