import React, { Component } from "react";

import PropTypes from "prop-types";
import Timeline from "../../components/Timeline";

class TimelinePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={styles.contentWrapper}>
        <Timeline />
      </div>
    );
  }
}

const styles = {
  contentWrapper: {
    padding: 20,
  },
  middlePriceTitle: {
    marginLeft: 10,
  },
  chartContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  formControl: {
    padding: 5,
    marginRight: 10,
    minWidth: 220,
  },
  selectControl: {
    padding: 5,
    marginRight: 10,
    minWidth: 350,
    marginTop: 3,
    zIndex: 99,
  },
  entryBar: {
    paddingLeft: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
};

TimelinePage.propTypes = {
  // Properties
  user: PropTypes.object,
};

export default TimelinePage;
