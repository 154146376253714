import useGeneratedTypeQuery from "../helpers/useGeneratedTypeQuery";
import { graphql } from "../../codegen/gql";
import { GetclientsQuery, QueryClientInput } from "../../codegen/gql/graphql";

const GET_SINGLECLIENT = graphql(`
  query getclients($input: QueryClientInput!) {
    getclients(input: $input) {
      companyName
      idExact
      country {
        id
        name
      }
      companyContact {
        id
        name
        email
        phone
        contactType
        isArchived
        lastContactedAt
        lastContactedBy {
          id
          fullName
          imageUrl
        }
        createdAt
        createdBy {
          id
          fullName
        }
        updatedAt
        updatedBy {
          id
          fullName
        }
        mainBroker {
          id
          brokerName
        }
        secondBroker {
          id
          brokerName
        }
        thirdBroker {
          id
          brokerName
        }
        company {
          id
          companyName
        }
        products {
          id
          name
        }
      }
      onBoardingStatus
    }
  }
`);

const useSingleClient = (input: QueryClientInput) => useGeneratedTypeQuery<GetclientsQuery, QueryClientInput>(GET_SINGLECLIENT, input);

export default useSingleClient;
