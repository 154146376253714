export const DESKS = {
  BIOGAS: "Biogas",
  APAC: "APAC",
  LATAM: "LATAM",
  VOLUNTARY_CARBON: "Voluntary Carbon",
  DACH: "DACH",
  ATLANTIC: "Atlantic",
  OTHER: "Other",
};

export const brokersGroupedByDesk = [
  { desk: DESKS.BIOGAS, brokers: [] },
  { desk: DESKS.APAC, brokers: [] },
  { desk: DESKS.LATAM, brokers: [] },
  { desk: DESKS.VOLUNTARY_CARBON, brokers: [] },
  { desk: DESKS.DACH, brokers: [] },
  { desk: DESKS.ATLANTIC, brokers: [] },
  { desk: DESKS.OTHER, brokers: [] },
];

export const brokerDeskMapping = {
  Oscar: DESKS.ATLANTIC,
  Leo: DESKS.ATLANTIC,
  Guido: DESKS.ATLANTIC,
  Mike: DESKS.ATLANTIC,
  Jakub: DESKS.ATLANTIC,
  Davide: DESKS.LATAM,
  Francesca: DESKS.LATAM,
  Yan: DESKS.APAC,
  Daniels: DESKS.APAC,
  Cassie: DESKS.APAC,
  Kuan: DESKS.APAC,
  Nacho: DESKS.APAC,
  Michel: DESKS.DACH,
  Paula: DESKS.DACH,
  Niek: DESKS.DACH,
  Philip: DESKS.DACH,
  Cornelius: DESKS.DACH,
  Khaled: DESKS.DACH,
  Tim: DESKS.DACH,
  Katharina: DESKS.DACH,
  Felix: DESKS.DACH,
  Riaan: DESKS.BIOGAS,
  Thomas: DESKS.BIOGAS,
  Jacob: DESKS.BIOGAS,
  Jonas: DESKS.BIOGAS,
  Yusheng: DESKS.VOLUNTARY_CARBON,
  Fabio: DESKS.VOLUNTARY_CARBON,
};

export const onboardingStatusDisplayText = {
  TODO: "To do",
  INPROGRESS: "In progress",
  DONE: "Completed",
  ERROR: "Faulty entry",
  NONEXISTENT: "Not initiated",
};

export const onBoardingStatusBackgroundColor = {
  TODO: "bg-[#A5A5A5]",
  INPROGRESS: "bg-[#F4B36E]",
  DONE: "bg-[#94CE94]",
  ERROR: "bg-red-500",
  NONEXISTENT: "bg-[#A5A5A5]",
};

export const userRoles = {
  BROKER: "Broker",
  DESK_MANAGER: "Desk manager",
  MANAGEMENT: "Management",
  SALES_SUPPORT: "Sales support",
  FINANCE: "Finance",
  ORG_ADMIN: "Admin",
  ENGINEER: "Engineer",
};

export const ROUTES = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  MY_DEALS: "/mydeals",
  MY_DEALS_DETAIL: "/mydeals/:dealId",
  CLIENTS: "/clients",
  CRM_DETAIL: "/crm/:companyId",
  CRM: "/crm",
  WORKLOAD: "/workload",
  NETWORK_GRAPH: "/networkgraph",
  LOCAL_USER: "/localUser/:userId",
  PRODUCT_HISTORY: "/producthistory/:productId",
  TIMELINE: "/timeline",
  ADD_DEAL: "/adddeal",
  FEEDBACK: "/feedback",
  SANDBOX: "/sandbox",
  SPREAD_VISUALISER: "/spreadvisualiser",
  EXTERNAL_LINKS: "/externallinks",
  REPORTING: "/reporting",
  STATISTICS: "/statistics",
  LEADERBOARD: "/leaderboard",
  DEAL_MANAGEMENT: "/dealmanagement",
  ADMIN_PAGE: "/adminpage",
  ADMIN: "/admin",
  INVOICES: "/invoices",
  ADD_DEAL_EXTENSIVE: "/adddealextensive",
  NOT_FOUND: "*",
};

export const PROCESSING_STATUS = {
  NOT_INITIATED: "Not initiated",
  PROCESSING: "Processing",
  SUCCESS: "Success",
  ERROR: "Error",
  FAILED: "Failed",
};
