import ExactLogin from "../../components/ExactLogin/ExactLogin";

function SandboxPage(props) {
  return (
    <>
      <ExactLogin></ExactLogin>
    </>
  );
}

export default SandboxPage;
