import { useMemo, useState } from "react";
import { useUser } from "./useUser";

interface PermissionsReturn {
  isAdmin: boolean;
  isEngineer: boolean;
  isFinance: boolean;
  hasRole: (role: string) => boolean;
  adminTurnedOff: boolean;
  setAdminTurnedOff: (value: boolean) => void;
  hasAdminView: boolean;
  isManager: boolean;
  userRole: string;
  isDevMode: boolean;
}

export const usePermissions = (): PermissionsReturn => {
  const { localUser } = useUser();
  const [adminTurnedOff, setAdminTurnedOff] = useState(false);

  return useMemo(() => {
    const overrideRole = process.env.REACT_APP_OVERRIDE_ROLE;
    const isDevMode = !!overrideRole;

    const effectiveRoles = isDevMode ? [overrideRole!] : localUser?.access?.roles || [];

    const hasRole = (role: string): boolean => {
      return effectiveRoles.includes(role);
    };

    const isAdmin = !!localUser && !hasRole("BROKER");
    const isEngineer = hasRole("ENGINEER");
    const isFinance = hasRole("FINANCE");
    const isManager = hasRole("MANAGEMENT") || hasRole("DESK_MANAGER");
    const userRole = isDevMode ? overrideRole! : localUser?.access?.roles?.[0] || "";

    const hasAdminView = isAdmin && !adminTurnedOff;

    return {
      isAdmin,
      isEngineer,
      isFinance,
      hasRole,
      adminTurnedOff,
      setAdminTurnedOff,
      hasAdminView,
      isManager,
      userRole,
      isDevMode,
    };
  }, [localUser, adminTurnedOff]);
};
