import React from "react";
import { useController, Control } from "react-hook-form";

interface FormInputProps {
  label: string;
  name: string;
  control: Control<any>;
  error?: string;
  type?: string;
  isRequired?: boolean;
}

export const RegularInputContext = ({ label, name, control, type = "text", isRequired = false }: FormInputProps) => {
  const {
    field,
    fieldState: { error: fieldError },
  } = useController({
    name,
    control,
  });

  return (
    <div className="relative h-12 w-full">
      <div
        className={`p-[4px] h-[2.7em] flex flex-nowrap rounded-xl border border-solid ${
          fieldError ? "border-red-200" : "border-gray-200"
        } text-base items-center`}
      >
        <div className="flex flex-row flex-nowrap items-center relative w-full h-full">
          <label className="inherit bg-white left-1 bottom-[30px] whitespace-nowrap md:m-auto px-1 text-[#9aa6b2] max-md:text-sm md:text-base" htmlFor={name}>
            {label}:
          </label>
          <div className="w-full h-full text-dark-gray-filters grow relative flex items-center flex-row flex-nowrap bg-transparent">
            <input
              {...field}
              type={type}
              id={name}
              required={isRequired}
              className={`w-full text-dark-gray-filters focus:outline-0 border-[1px] border-transparent 
                focus:border-[1px] text-opacity-100
                ${fieldError ? "bg-red-50" : ""} 
                h-[100%] pl-2 pr-2 rounded-[6px]`}
            />
          </div>
        </div>
      </div>
      {fieldError && <span className="text-red-500 text-sm absolute -bottom-6 left-2">{fieldError.message}</span>}
    </div>
  );
};
