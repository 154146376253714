import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import GET_CLIENTS_FULL from "./query_clients_full";

function useClientsFull() {
  const [clients, setClients] = useState(false);

  const { loading, error, data, refetch, networkStatus } = useQuery(GET_CLIENTS_FULL, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setClients(() => {
      if (loading && (!data || data.length === 0)) {
        return false;
      } else if (error) {
        console.error("Error getting clients");
        return false;
      } else {
        return data;
      }
    });
  }, [loading, error, data, refetch]);

  return [clients, refetch, networkStatus];
}

export default useClientsFull;
