import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { auth } from "../../firebase";
import authentication from "../../services/authentication";
import EmptyState from "../EmptyState";
import { useNavigate } from "react-router-dom";

const HomeContent = ({ user, openSnackbar }) => {
  const navigate = useNavigate();

  useEffect(() => {
    signInWithEmailLink();
  }, []);

  const signInWithEmailLink = () => {
    if (user) {
      return;
    }

    const emailLink = window.location.href;

    if (!emailLink) {
      return;
    }

    if (auth.isSignInWithEmailLink(emailLink)) {
      let emailAddress = localStorage.getItem("emailAddress");

      if (!emailAddress) {
        navigate("/");

        return;
      }

      authentication
        .signInWithEmailLink(emailAddress, emailLink)
        .then((value) => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;

          openSnackbar(`Signed in as ${displayName || emailAddress}`);
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/expired-action-code":
            case "auth/invalid-email":
            case "auth/user-disabled":
              openSnackbar(message);
              break;

            default:
              openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          navigate("/");
        });
    }
  };

  if (user) {
    return <EmptyState title="Welcome" description="Click the menu items above to select a page" />;
  }

  return <EmptyState title="Welcome" description="Sign in to get started. Remember to set a First name and Last Name before entering bids and asks" />;
};

HomeContent.propTypes = {
  // Properties
  user: PropTypes.object,
  openSnackbar: PropTypes.func.isRequired,
};

export default HomeContent;
