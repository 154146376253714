import ContactOverview from "../../components/ContactOverview/ContactOverview";

const CrmPage = () => {
  return (
    <div className="root">
      <h1 className="text-2xl m-0 p-0 inline-block">CRM</h1>
      <ContactOverview />
    </div>
  );
};

export default CrmPage;
