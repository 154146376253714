import React, { useState } from "react";
import MessageIcon from "@material-ui/icons/Mail";
import { Link } from "react-router-dom";
import useDealConfirmationsCount from "../../typedHooks/queries/queryDealConfirmationsCount";
import useOpenDealNoteCount from "../../typedHooks/queries/queryOpenDealNoteCount";
import { addDays } from "date-fns";
import useDealConditionsCount from "../../typedHooks/queries/queryDealConditionsCount";
import { useUser } from "../../typedHooks/useUser";
export default function MentionNotificationIconButton() {
  const { localUser } = useUser();
  const { dataFromQuery: openDealNoteCount } = useOpenDealNoteCount({});
  const { dataFromQuery: dealConfirmationsCount } = useDealConfirmationsCount({ userIds: [localUser?.id ?? 0], isConfirmed: false });
  const [oneWeekFromNow, setOneWeekFromNow] = useState(addDays(new Date(), 7));
  const { dataFromQuery: openDealConditionsCount } = useDealConditionsCount({
    isConfirmed: false,
    userIds: [localUser?.id ?? 0],
    toBeResolvedBefore: oneWeekFromNow,
  });
  const openDealNoteCountInt: number = openDealNoteCount?.opendealnotecount ?? 0;
  const dealConfirmationsCountInt: number = dealConfirmationsCount?.dealconfirmationscount ?? 0;
  const dealConditionsCountInt: number = openDealConditionsCount?.dealconditionscount ?? 0;
  const totalNotificationCount: number = openDealNoteCountInt + dealConfirmationsCountInt + dealConditionsCountInt;

  return (
    <div className="p-3 pr-1 rounded-full hover:bg-white hover:bg-opacity-10">
      <Link className="relative mr-2" to={"/"}>
        <MessageIcon />
        {totalNotificationCount > 0 && (
          <div
            data-testid="noti-badge"
            className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 rounded-full -top-[1.06rem] -right-3 border-gray-900"
          >
            {totalNotificationCount > 99 ? "99+" : totalNotificationCount}
          </div>
        )}
      </Link>
    </div>
  );
}
