import useGeneratedTypeQuery from "../helpers/useGeneratedTypeQuery";
import { graphql } from "../../codegen/gql";
import { ProductCategoriesQuery } from "../../codegen/gql/graphql";

const GET_PRODUCTCATEGORIES = graphql(`
  query productCategories {
    productcategories {
      id
      name
      products {
        id
        name
        productCategory {
          id
          name
        }
        exactItem {
          idExact
          code
          description
        }
        isActive
      }
    }
  }
`);

const useProductCategories = (input: {}) => useGeneratedTypeQuery<ProductCategoriesQuery, {}>(GET_PRODUCTCATEGORIES, input);

export default useProductCategories;
