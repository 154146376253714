import { useState } from "react";

interface FormState {
  error: string | null;
  success: string | null;
  isLoading: boolean;
}

interface UseFormStateReturn extends FormState {
  startSubmit: () => void;
  setFormError: (error: string) => void;
  setFormSuccess: (message: string) => void;
  resetForm: () => void;
}

export const useFormState = (): UseFormStateReturn => {
  const [formState, setFormState] = useState<FormState>({
    error: null,
    success: null,
    isLoading: false,
  });

  const startSubmit = () => {
    setFormState({
      error: null,
      success: null,
      isLoading: true,
    });
  };

  const setFormError = (error: string) => {
    setFormState((prev) => ({
      ...prev,
      error,
      isLoading: false,
    }));
  };

  const setFormSuccess = (message: string) => {
    setFormState((prev) => ({
      ...prev,
      success: message,
      isLoading: false,
    }));
  };

  const resetForm = () => {
    setFormState({
      error: null,
      success: null,
      isLoading: false,
    });
  };

  return {
    ...formState,
    startSubmit,
    setFormError,
    setFormSuccess,
    resetForm,
  };
};
