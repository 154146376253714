import React, { createContext, useState, useEffect, ReactNode } from "react";
import { auth } from "../firebase";
import olyxbase, { userDataEmitter } from "../services/olyxbase";
import { ILocalUser } from "../types/user.types";
import { IUserContextType } from "../types/user.types";

export const UserContext = createContext<IUserContextType | null>(null);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [localUser, setLocalUser] = useState<ILocalUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        setLocalUser(null);
        setLoading(false);
      } else {
        fetchUserData();
      }
    });

    const handleUserDataUpdate = (data: ILocalUser) => {
      setLocalUser((prevUser) => {
        if (!prevUser) return data;
        if (
          prevUser.id !== data.id ||
          prevUser.access.roles !== data.access.roles ||
          prevUser.fullName !== data.fullName ||
          prevUser.access.permissions?.length !== data.access.permissions?.length
        ) {
          return data;
        }
        return prevUser;
      });
    };

    userDataEmitter.on("userDataReceived", handleUserDataUpdate);

    return () => {
      unsubscribe();
      userDataEmitter.off("userDataReceived", handleUserDataUpdate);
    };
  }, []);

  const fetchUserData = async () => {
    setLoading(true);

    try {
      if (!auth.currentUser) {
        setLoading(false);
        return;
      }

      const user = await olyxbase.getMe();

      if (user) {
        setLocalUser(user as ILocalUser);
      } else {
        setLocalUser(null);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateUser = (user: ILocalUser | null): void => {
    setLocalUser(user);
  };

  return (
    <UserContext.Provider
      value={{
        localUser,
        loading,
        updateUser,
        refreshUser: fetchUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
