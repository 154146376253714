import { Link, useLocation } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React from "react";

const Breadcrumbs = ({ pageName }: { pageName: string | undefined }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <nav aria-label="breadcrumb">
      <ul className="flex space-x-2 text-gray-600 items-center ml-2 font-regular">
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          const displayName = isLast && pageName ? pageName : value;

          return (
            <React.Fragment key={to}>
              {index > 0 && (
                <span className="text-gray-400">
                  <KeyboardArrowRightIcon />
                </span>
              )}
              {isLast ? (
                <span className=" text-primary-blue">{displayName}</span>
              ) : (
                <Link to={to} className="hover:underline text-gray-400 ">
                  {value}
                </Link>
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
