import { TypeCompiler } from "@sinclair/typebox/compiler";
import { ProductCategorySchema } from "./ProductCategoryForm.schema";

const C = TypeCompiler.Compile(ProductCategorySchema);

export const productCategoryResolver = async (values: any) => {
  const isValid = C.Check(values);

  if (isValid) {
    return {
      values,
      errors: {},
    };
  }

  const errors = [...C.Errors(values)][0];
  return {
    values: {},
    errors: {
      name: {
        type: "validation",
        message: errors?.message || "Invalid product class name",
      },
    },
  };
};
