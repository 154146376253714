import React, { useState, useRef, useEffect } from "react";
import { useTable, useExpanded, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from "react-table";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { motion, AnimatePresence } from "framer-motion";
import TextInput from "../../components/FilterForm/TextInput";
import StatusButton from "../../components/StatusButton/StatusButton";
import { matchSorter } from "match-sorter";
import TablePageNavigation from "./TablePageNavigation";
import AvatarTooltip from "../AvatarTooltip/AvatarTooltip";
import { CheckListItemType } from "../../codegen/gql/graphql";

import styled from "styled-components";

let offerInput;
let offersPage;
// filter const
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  const StyledInput = styled.input`
    display: block;
    margin: 0px 0px;
    border 1px solid black;
    max-width: 7.5rem;
    padding: 0rem;
  `;

  return (
    <div style={styles.contentWrapper}>
      <StyledInput
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const updateSearchInput = useAsyncDebounce(() => {
    setGlobalFilter(offerInput);
  }, 200);

  useEffect(() => {
    if (offersPage === true && offerInput !== "" && offerInput !== globalFilter) {
      updateSearchInput();
    }
  });

  const checkAndSubmitSearchValue = (value) => {
    const valueToLowerCase = value.toLowerCase();
    let valueToSubmit;
    if (valueToLowerCase === "onboarded" || valueToLowerCase === "kyc finished") {
      valueToSubmit = "DONE";
    } else if (valueToLowerCase === "in progress") {
      valueToSubmit = "INPROGRESS";
    } else if (valueToLowerCase === "to do") {
      valueToSubmit = "TODO";
    } else if (valueToLowerCase === "faulty entry") {
      valueToSubmit = "ERROR";
    } else if (valueToLowerCase === "not initiated") {
      valueToSubmit = "NONEXISTENT";
    } else {
      valueToSubmit = value;
    }
    onChange(valueToSubmit);
  };

  return (
    <div>
      {offersPage === undefined ? (
        <div className="w-full  md:w-1/4 mb-8">
          <h1 className="text-2xl mt-2 mb-4">Clients</h1>
          <TextInput
            label={"Search"}
            name={"search"}
            onChange={(e) => {
              checkAndSubmitSearchValue(e.target.value);
            }}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
// end filter const

const TableExpanding = (props) => {
  offerInput = props.searchInput;
  offersPage = props.dontShow;
  //
  const [columns, setColumns] = useState([...props.columns]);
  // const [data, setData] = useState([...props.data])
  // const [data, setData] = useState(props.filterData({'deals': [...props.data]}))
  const [data, setData] = useState(props.filterData([...props.data]));
  const [originalData, setOriginalData] = useState([...props.data]);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    setData(() => props.filterData([...props.data]));
  }, [props]);
  //
  // filter consts

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    state: { expanded, globalFilter, pageIndex, pageSize },
    preGlobalFilteredRows,
    setGlobalFilter,
    toggleRowExpanded,
    toggleAllRowsExpanded,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0, sortBy: props.sortBy ? props.sortBy : [] },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  return (
    <div className="bg-white w-full p-4 lg:p-8 rounded-2xl mt-10">
      <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
      <table id="ClientPageOverview" className="w-full" {...getTableProps()}>
        <thead className="pb-20">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <>
                  <td {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="inline-block h-8">
                      <div className="flex">
                        <span className="pt-1 uppercase tracking-wider text-[10px] md:text-xs antialiased md:font-semibold text-gray-400 hover:text-gray-600">
                          {column.render("Header")}
                        </span>
                        <div className="arrow-div inline-block">
                          <div className="arrow w-5 h-5">
                            {column.isSorted ? column.isSortedDesc ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon /> : ""}{" "}
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    {/* <div> */}
                    {/*   {column.canFilter ? column.render("Filter") : null} */}
                    {/* </div> */}
                  </td>
                </>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment>
                {/* <TableRow {...row.getRowProps()}> */}
                <tr className="text-left font-thin border-solid border-b last:border-b-0 border-gray-100 ">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        onClick={() => {
                          toggleAllRowsExpanded(false);
                          toggleRowExpanded(row.id, !row.isExpanded);
                          setSelectedRow(row.id);
                        }}
                        className={`h-14 py-4 ${cell.column.id === "expander" ? "w-[24px] md:w-[32px]" : "min-w-fit"} ${
                          cell.column.Header === "Broker Provided" ? "flex items-center w-full" : ""
                        }`}
                        {...cell.getCellProps()}
                      >
                        {(cell.column.Header === "Client name" || cell.column.id === "expander") && <p>{cell.render("Cell")}</p>}
                        {cell.column.Header === "Broker Provided" && (
                          <>
                            <div className="w-[22px] h-[22px] mr-2">
                              <AvatarTooltip
                                img={cell.row.original.broker.userInfo?.imageUrl ?? ""}
                                broker={cell.row.original.broker.userInfo?.fullName ?? ""}
                                hover={true}
                              ></AvatarTooltip>
                            </div>
                            <p className="text-xs max-w-[calc(100%-30px)]">{cell.render("Cell")}</p>
                          </>
                        )}
                        {cell.column.Header === "Onboarding status" && (
                          <StatusButton statusType={[CheckListItemType.Clientonboarding]} status={cell.row.original.onBoardingStatus} />
                        )}
                        {cell.column.Header === "KYC status" && (
                          <StatusButton statusType={[CheckListItemType.Clientkyc]} status={cell.row.original.kycStatus} />
                        )}
                      </td>
                    );
                  })}
                </tr>
                <AnimatePresence>
                  {row.id === selectedRow && row.isExpanded ? (
                    <tr>
                      {" "}
                      <td className="bg-gray-50 rounded-lg -top-1 relative" colSpan={visibleColumns.length}>
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{
                            height: "auto",
                            transition: {
                              duration: 0.2,
                            },
                          }}
                          exit={{
                            height: 0,
                            transition: {
                              delay: 0.2,
                              duration: 0.2,
                            },
                          }}
                        >
                          {" "}
                          {props.renderRowSubComponent(data, row.original)}
                        </motion.div>
                        {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                        {/* {props.renderRowSubComponent({ row })} */}
                      </td>
                    </tr>
                  ) : null}
                </AnimatePresence>
                {/* </TableRow> */}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <TablePageNavigation
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </div>
  );
};

const styles = {
  rowWrapper: {
    maxWidth: 50,
    paddingRight: 30,
  },
  contentWrapper: {
    maxWidth: "221px",
  },
};
export default TableExpanding;
