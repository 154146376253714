import AutoCompleteDropdownProps from "../../../interfaces/AutoCompleteDropdownProps";
import DropDownList from "../DropDownList/DropDownList";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import TextField from "../TextField/TextField";
import sortStringsAlphabetically from "./../../../services/helperFunctions/sortStringsAlphabetically";
import useComponentVisible from "../../../typedHooks/useComponentVisible";
import useKeyPress from "../../../typedHooks/useKeyPress";
import { Option } from "../../../interfaces/AutoCompleteDropdownProps";
import { useEffect, useReducer, useRef, useState } from "react";

const AutoCompleteDropdown = (props: AutoCompleteDropdownProps): JSX.Element => {
  const textRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const options = [...props.options].sort((option1: Option, option2: Option) => sortStringsAlphabetically(option1.name, option2.name));

  const [isEditorFocused, setIsEditorFocused] = useState<boolean>(false);
  const [elemId, setElemId] = useState<string>(props.initialValue);
  const [isElementSelected, setIsElementSelected] = useState<boolean>(props.initialValue !== "0");
  const [textFieldValue, setTextFieldValue] = useState<string>(options.find((item) => item.id === props.initialValue)?.name ?? "");
  // style that displays the menu above or below
  const [dropdownStyle, setDropdownStyle] = useState<string>("");

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const enterPressed = useKeyPress("Enter");
  const tabPressed = useKeyPress("Tab");
  const backspacePressed = useKeyPress("Backspace");
  const deletePressed = useKeyPress("Delete");

  const initialState: { selectedIndex: number } = {
    selectedIndex: options.findIndex((e) => e.id === elemId) !== -1 ? options.findIndex((e) => e.id === elemId) : 0,
  };

  const reducer = (state: { selectedIndex: number }, action: any) => {
    switch (action.type) {
      case "arrowUp":
        return {
          selectedIndex: state.selectedIndex - 1,
        };
      case "arrowDown":
        return {
          selectedIndex: state.selectedIndex + 1,
        };
      case "select":
        return {
          selectedIndex: state.selectedIndex,
        };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    menuRef?.current?.scroll();
    state.selectedIndex = options.findIndex((e) => e.id === elemId) !== -1 ? options.findIndex((e) => e.id === elemId) : 0;
    // display the menu when tabbing
    tabPressed && isEditorFocused ? setIsComponentVisible(!isEditorFocused) : setIsComponentVisible(isEditorFocused);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabPressed, menuRef, elemId]);

  useEffect(() => {
    if (textRef.current && props.order === "order-first") {
      textRef.current.focus();
      setIsComponentVisible(true);
    }
  }, [props.order]);

  useEffect(() => {
    //scrolling when action is performed
    menuRef?.current?.scroll();
    if (isEditorFocused && arrowUpPressed && state.selectedIndex > 0) {
      dispatch({ type: "arrowUp" });
    } else if (isEditorFocused && arrowDownPressed && state.selectedIndex < options.length - 1) {
      // if menu is close and press arrow down, it opens
      !isComponentVisible && setIsComponentVisible(true);
      dispatch({ type: "arrowDown" });
    } else if (isEditorFocused && enterPressed) {
      dispatch({ type: "select" });
      const element =
        elemId === "0"
          ? options.filter((e) => e.name.toLocaleLowerCase().includes(textFieldValue)).find((e, idx) => idx === state.selectedIndex)
          : options.find((e, idx) => idx === state.selectedIndex);
      element && selectElement(element);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrowUpPressed, arrowDownPressed, enterPressed, menuRef]);

  // decide where to render the dropdown menu changing a conditional style
  useEffect(() => {
    const elementRect = ref.current?.getBoundingClientRect();
    const spaceAbove = elementRect?.top ? elementRect?.top : 0;
    const spaceBelow = elementRect?.bottom ? window.innerHeight - elementRect?.bottom : window.innerHeight;
    setDropdownStyle(spaceAbove > spaceBelow ? "bottom-12" : "top-12");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current?.getBoundingClientRect()]);

  // pass the id of the selected element to the parent that can query the data and update textField
  useEffect(() => {
    props.onChange(elemId);
    const itemExistsInOptions = options.find((item) => item.id === elemId)?.name;
    state.selectedIndex = options.findIndex((e) => e.id === elemId) !== -1 ? options.findIndex((e) => e.id === elemId) : 0;
    if (!itemExistsInOptions) {
      setTextFieldValue("");
      setElemId("0");
      setIsElementSelected(false);
    } else {
      setTextFieldValue(options.find((item) => item.id === elemId)?.name ?? "");
    }
    // style that displays the menu above or below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.length, elemId]);

  const handleChange = (event: any) => {
    setTextFieldValue(event.target.value.toLowerCase());
    setIsComponentVisible(true);
    if ((deletePressed || backspacePressed) && elemId !== "0") {
      setTextFieldValue("");
      setElemId("0");
      setIsElementSelected(false);
    }
    // when text field is empty reset the ID to 0
    if (event.target.value === "") {
      setElemId("0");
      setIsElementSelected(false);
    }
  };

  const handleClickCrossButton = () => {
    setElemId("0");
    setTextFieldValue("");
    setIsElementSelected(false);
  };

  const selectElement = (option: { id: string; name: string }) => {
    setElemId(option.id);
    setTextFieldValue(option.name);
    setIsComponentVisible(false);
    setIsElementSelected(true);
  };

  const inputWidthEmptyState = 20;
  const inputWidthFocusState = 80;
  const inputWidth = textFieldValue.length ? inputWidthFocusState : inputWidthEmptyState;

  return (
    <div
      ref={ref}
      id="rootDiv"
      className={`relative ${props.customFormStyles ?? "h-12 max-w-fit"} ${props.order ?? ""} ${props.additionalStyles ? props.additionalStyles : ""}`}
    >
      <DropDownList
        additionalStyle={isComponentVisible ? "" : "hidden"}
        ref={menuRef}
        elemId={elemId}
        options={options}
        selectElement={selectElement}
        textField={textFieldValue}
        dropDownStyle={dropdownStyle}
        selectedIndex={state.selectedIndex}
      ></DropDownList>
      <div
        className={
          "relative bg-white h-[2.7em] p-[4px] flex flex-row flex-nowrap place-content-between rounded-xl border border-solid border-gray-200 text-base "
        }
      >
        <div className={props.customFormStyles ? "w-full flex flex-row flex-nowrap" : "flex flex-row flex-nowrap"}>
          <div
            className={props.customFormStyles ? "flex flex-row flex-nowrap items-center relative w-full" : "flex flex-row flex-nowrap items-center relative"}
          >
            <label
              htmlFor={props.label}
              className={`${props.hasLabelsOnTop ? "absolute " : "inherit "} 
              whitespace-nowrap md:m-auto px-2 text-[#9aa6b2] max-md:text-sm md:text-base left-1 bottom-[30px] bg-white  `}
            >
              {props.label}:
            </label>
            <TextField
              isElementSelected={isElementSelected}
              inputRef={textRef}
              onFocus={() => {
                setIsEditorFocused(true);
              }}
              onBlur={() => {
                setIsEditorFocused(false);
              }}
              tabIndex={0}
              customStyle={props.customFormStyles}
              inputWidth={inputWidth}
              onClick={() => {
                setIsComponentVisible(true);
                menuRef?.current?.scroll();
              }}
              label={props.label}
              isRequired={props.isRequired}
              placeholder={props.placeholder || ""}
              value={textFieldValue}
              onChange={handleChange}
              onClickCrossButton={handleClickCrossButton}
            ></TextField>
          </div>
        </div>
        <div className="flex">
          <button
            tabIndex={-1}
            className=" w-[40px] bg-transparent back opacity-50 text-dark-gray-filters"
            type="button"
            onClick={() => {
              if (!isComponentVisible) textRef.current?.focus();
              menuRef?.current?.scroll();
              setIsComponentVisible(!isComponentVisible);
            }}
          >
            {isComponentVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AutoCompleteDropdown;
