import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AutoComplete from "@material-ui/lab/Autocomplete";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import helpers from "../../services/helpers";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: "#9BA3AE",
    height: "40px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#E1E1E1",
      borderRadius: "10px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#E1E1E1",
      borderRadius: "10px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#E1E1E1",
      borderRadius: "10px",
    },
  },
  option: {
    backgroundColor: "white",
    color: "#171720",
    padding: "10px",
    margin: "1px !important",
  },
  "&.MuiAutocomplete-input": {
    backgroundColor: "red",
  },
  clearIndicator: {
    height: "29px",
    color: "#364E66",
  },
}));

function AutoCompleteInput(props) {
  const defaultWidth = 25;
  const widthBasedOnLAbel = props.label.length + defaultWidth;
  const maxWidthValue = widthBasedOnLAbel >= 40 ? 40 : widthBasedOnLAbel;
  const inputWidth = props.value === "0" || props.value === null ? `${defaultWidth}ch` : `${maxWidthValue}ch`;
  const classes = useStyles();
  const { isMobile } = useWindowDimensions();
  return (
    <>
      <AutoComplete
        popupIcon={<KeyboardArrowDownIcon />}
        autoWidth={true}
        classes={classes}
        value={props.value === "0" ? null : props.value}
        className={`${props.value === "0" || props.value === null ? "select" : "selected"} ${props.customStyle ?? ""} selection`}
        options={[...props.options]
          .sort((a, b) =>
            a.name && b.name
              ? helpers.compareStrings(a.name, b.name)
              : a.companyName && b.companyName
              ? helpers.compareStrings(a.companyName, b.companyName)
              : a.brokerName && b.brokerName
              ? helpers.compareStrings(a.brokerName, b.brokerName)
              : helpers.compareStrings(a, b)
          )
          .map((option) => option.id)}
        getOptionLabel={(option) => props.getCustomOptionLabel(option, props.options)}
        onChange={props.onChange}
        style={{ width: `${isMobile ? "100%" : inputWidth}` }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={`${props.value === "0" || props.value === null ? "All" : props.label}`}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: <span className="placeholder mx-2 capitalize">{props.name}:</span>,
            }}
            fullWidth
          />
        )}
      />
    </>
  );
}

export default AutoCompleteInput;
