import { onboardingStatusDisplayText, onBoardingStatusBackgroundColor } from "../../../services/constants";
import { ClientOnBoardingStatus } from "../../../codegen/gql/graphql";
import { useParams } from "react-router-dom";
import ExactSyncStatusDisplay from "../../../components/ExactSyncStatusDisplay/ExactSyncStatusDisplay";

interface clientStatuses {
  onBoardingStatus: ClientOnBoardingStatus;
}

const CompanyStatuses = ({ exactId, statuses }: { exactId: string | null; statuses: clientStatuses }) => {
  const onBoardingStatus = statuses.onBoardingStatus;
  const { companyId } = useParams<{ companyId: string }>();

  return (
    <section className="w-full flex flex-wrap gap-y-[24px] mt-[16px]">
      <div className="flex flex-wrap sm:flex-nowrap gap-4 w-full items-start">
        <div className="w-fit flex items-center gap-2">
          <p className="leading-none font-sm font-regular">Onboarding status:</p>
          <div className={"h-[24px] flex bg-[#fbfbfb] rounded-[12px] p-2 items-center gap-2"}>
            <div className={`w-4 h-4 rounded-full ${onBoardingStatusBackgroundColor[onBoardingStatus]}`}></div>
            <span className="text-sm font-regular leading-none">{onboardingStatusDisplayText[onBoardingStatus]}</span>
          </div>
        </div>
        <div className="w-fit flex items-center gap-2">
          <p className="text-sm font-regular leading-none">Exact status:</p>
          <ExactSyncStatusDisplay idExact={exactId} companyId={companyId!} />
        </div>
      </div>
    </section>
  );
};

export default CompanyStatuses;
