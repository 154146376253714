import { gql } from "@apollo/client";

const GET_CLIENTS_FULL = gql`
  query getClients {
    clients {
      id
      companyName
      firstDealDate
      companyNameExact
      idExact
      onBoardingStatus
      kycStatus
      broker {
        brokerName
        id
        userInfo {
          fullName
          imageUrl
        }
      }
      country {
        name
        id
      }
      clientGroup {
        clientGroupName
        id
      }
    }
  }
`;

export default GET_CLIENTS_FULL;
