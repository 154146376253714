import { Link } from "react-router-dom";
import DescriptionIcon from "@material-ui/icons/Description";
import AvatarTooltip from "../../AvatarTooltip/AvatarTooltip";
import OpenDealFormButton from "../../OpenDealFormButton/OpenDealFormButton";

function DealContainer({ deal, history }) {
  return (
    <div className="w-full" key={deal.id}>
      <div className="py-3 xl:py-4 px-3 xl:px-6 rounded-md border-[1px] bg-white w-full justify-start align-center grid grid-cols-12">
        <div className="col-start-1 col-end-3 flex">
          <OpenDealFormButton deal={deal} />
        </div>
        <div className="col-start-6 col-end-10 xl:col-start-3 xl:col-end-5 flex">
          <a
            href={`https://drive.google.com/drive/u/0/search?q=${deal.olyxRef}`}
            rel="noreferrer"
            target="_blank"
            className="w-fit min-w-[180px] gap-2 border border-gray-light px-2 py-0.5 rounded-xl text-sm flex items-center"
          >
            <span className="mr-2 text-gray-dark">Deal Confirmation</span>
            <span className="">
              {" "}
              <a href={`https://drive.google.com/drive/u/0/search?q=${deal.olyxRef}`} rel="noreferrer" target="_blank">
                <DescriptionIcon />
              </a>
            </span>
          </a>
        </div>
        <div className="col-start-5 col-end-9 items-center hidden xl:flex">
          <div className="w-[calc(50%-16px)] gap-2 flex items-center text-xs md:text-sm rounded-xl">
            <div className="min-w-[24px] w-[24px] min-h-[24px] h-[24px]">
              <AvatarTooltip broker={deal.brokerSeller?.brokerName ?? ""} img={deal.brokerSeller?.userInfo?.imageUrl ?? "default.png"} hover={true} />
            </div>
            <span
              className={`w-full text-[12px] whitespace-nowrap text-ellipsis overflow-hidden px-2 rounded-md ${
                deal?.seller?.companyName === "NEW CLIENT" ? "text-white bg-primary-blue text-center" : "text-black border-[1px] border-light-grey"
              }`}
            >
              {deal?.seller?.companyName ?? "no seller"}
            </span>
          </div>
          <p className="w-[8px] mx-auto">/</p>
          <div className="w-[calc(50%-16px)] gap-2 flex items-center text-xs md:text-sm rounded-xl justify-end">
            <div className="min-w-[24px] w-[24px] min-h-[24px] h-[24px]">
              <AvatarTooltip broker={deal.brokerBuyer?.brokerName ?? ""} img={deal.brokerBuyer?.userInfo?.imageUrl ?? "default.png"} hover={true} />
            </div>
            <span
              className={`w-full text-[12px] whitespace-nowrap text-ellipsis overflow-hidden px-2 rounded-md ${
                deal?.buyer?.companyName === "NEW CLIENT" ? "text-white bg-primary-blue text-center" : "text-black border-[1px] border-light-grey"
              }`}
            >
              {deal?.buyer?.companyName ?? "no buyer"}
            </span>
          </div>
        </div>
        <div className="col-start-12 col-end-13 flex">
          <Link
            className=" cursor-pointer link-deal text-primary-blue h-[20px] absolute right-0 top-0"
            to={{
              pathname: `/mydeals/${deal.id}`,
            }}
          >
            {deal.olyxRef}
          </Link>
        </div>
      </div>
    </div>
  );
}
export default DealContainer;
