import React, { useMemo } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import SingleClient from "./SingleClient";
import TableExpanding from "./TableExpanding";
import useClientsFull from "../../hooks/useClientsFull";
import LaunchScreen from "../../components/LaunchScreen/LaunchScreen";

function ClientsTableExpanding(props) {
  const filterData = (data) => {
    const filteredData = data.map((client) => {
      const formattedClient = { ...client };
      formattedClient["firstDealDate"] = formattedClient["firstDealDate"].slice().substring(0, 10);
      return formattedClient;
    });
    return filteredData;
  };

  const [clients, refetch] = useClientsFull();

  const columns = useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
        ),
      },
      { Header: "Client name", accessor: "companyName" },
      { Header: "Broker Provided", accessor: "broker.userInfo.fullName" },
      { Header: "Onboarding status", accessor: "onBoardingStatus" },
      { Header: "KYC status", accessor: "kycStatus" },
    ],
    []
  );

  const renderRowSubComponent = React.useCallback((data, rowOriginal) => {
    const currentClient = data.filter((client) => client.id === rowOriginal.id);
    return <SingleClient data={currentClient[0]} refetchClientsFunction={refetch} />;
  }, []);

  if (clients) {
    return <TableExpanding renderRowSubComponent={renderRowSubComponent} data={clients.clients} columns={columns} filterData={filterData} />;
  } else {
    return (
      <>
        <LaunchScreen />
        <div>Loading data. This might take up to 10 seconds. </div>
      </>
    );
  }
}

export default ClientsTableExpanding;
