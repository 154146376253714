import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextFieldWithLabel from "../../components/FormFields/TextFieldWithLabel";
import InvoicesTableExpanding from "../../components/InvoicesTableExpanding/InvoicesTableExpanding";
import useInvoices from "../../typedHooks/queries/queryInvoices";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

function InvoicePage() {
  const initialValues = { take: 30 };
  const { dataFromQuery, navigateForward, navigateBack, searchForMoreData } = useInvoices(initialValues);
  const cursor = dataFromQuery?.invoices && dataFromQuery?.invoices.length > 0 ? dataFromQuery?.invoices.slice(-1)[0]["id"] : undefined;

  const validationSchema = {};

  return (
    <div className="w-10/12 mx-auto mt-10 bg-white rounded-lg p-8">
      <div className="flex flex-wrap items-start justify-start">
        <h2 className="text-2xl mt-2 mb-4">Invoices</h2>
      </div>
      <div>
        <Formik initialValues={initialValues} validationSchema={Yup.object(validationSchema)} enableReinitialize={false} onSubmit={searchForMoreData}>
          {(props) => (
            <Form>
              <div className="flex flex-wrap items-center justify-start gap-4 bg-white">
                <TextFieldWithLabel
                  label="OrderNumber"
                  name="orderNumber"
                  onChange={(event) => {
                    props.setValues({
                      ...props.values,
                      orderNumber: event.target.value ? event.target.value : undefined,
                    });
                  }}
                  onBlur={props.handleBlur}
                  value={props.values.orderNumber}
                />
                <TextFieldWithLabel
                  label="Description"
                  name="description"
                  onChange={(event) => {
                    props.setValues({
                      ...props.values,
                      description: event.target.value ? event.target.value : undefined,
                    });
                  }}
                  onBlur={props.handleBlur}
                  value={props.values.description}
                />
                <div className={"px-1 flex flex-nowrap rounded-[5px] text-base items-center h-[56px] max-md:text-xs md:text-base"}>
                  <Field
                    className="mx-2 checkbox block relative cursor-pointer select-none max-md:text-xs md:text-base"
                    label="Has Incorrect Allocation"
                    name="hasIncorrectAllocation"
                    type="checkbox"
                  />
                  <span className="h-3 w-3 CheckboxCheckmark absolute max-md:top-0.5 md:top-1.5 -left-4 rounded-sm border border-solid border-gray-darker"></span>
                  <span className="text-primary-blue max-md:text-xs md:text-base mr-1">{"Has Incorrect Allocation"}</span>
                </div>
              </div>
              <div className="button-container text-white">
                <div className="px-4 flex flex-wrap items-start justify-start">
                  <div className="px-4 py-2 button text-white">
                    <button type="submit">Search</button>
                  </div>
                  <div className="button px-4 py-2 text-white">
                    <button
                      onClick={() => {
                        props.handleReset();
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div>
        <InvoicesTableExpanding data={dataFromQuery?.invoices ?? []} />
      </div>
      <div className="p-4 flex flex-wrap items-start justify-center pagination">
        <button
          onClick={() => {
            navigateBack();
            window.scrollTo({ top: 220, behavior: "smooth" });
          }}
        >
          <NavigateBeforeIcon />
        </button>
        <button
          onClick={() => {
            navigateForward(cursor);
            window.scrollTo({ top: 220, behavior: "smooth" });
          }}
        >
          <NavigateNextIcon />
        </button>
      </div>
    </div>
  );
}

export default InvoicePage;
