import AddDealSelect from "./../../components/AddFormDeal/AddDealSelect";

function AddDealExtensivePage() {
  return (
    <>
      <AddDealSelect extensive={true} />
    </>
  );
}

export default AddDealExtensivePage;
