import { useNavigate } from "react-router-dom";
import AdminDeals from "../../components/AdminPage/AdminDeals";
import AdminProducts from "../../components/AdminPage/AdminProducts";
import { useState, useEffect } from "react";
import queryString from "query-string";

function AdminPage() {
  const navigate = useNavigate();
  const queryParams = queryString.parse(window.location.search);

  const [currentView, setCurrentView] = useState(queryParams.view || "Deals");

  useEffect(() => {
    const newQueryParams = { ...queryParams, view: currentView };
    navigate({
      search: queryString.stringify(newQueryParams),
    });
  }, [currentView]);

  return (
    <div styles={styles.contentWrapper}>
      <ul className="bg-[#142a41] p-6 flex flex-wrap w-full justify-start gap-2">
        <li className="cursor-pointer">
          <button
            className={`rounded-sm px-[18px] py-[4px] text-white border-b-2 ${
              currentView === "Deals" ? "border-primary-blue bg-white/10" : "border-transparent"
            }`}
            onClick={() => setCurrentView("Deals")}
          >
            Deals
          </button>
        </li>
        <li className="cursor-pointer px-18px py-8px">
          <button
            className={`rounded-sm px-[18px] py-[4px] text-white border-b-2 ${
              currentView === "Products" ? "border-primary-blue bg-white/10" : "border-transparent"
            }`}
            onClick={() => setCurrentView("Products")}
          >
            Products
          </button>
        </li>
      </ul>
      {currentView === "Deals" && <AdminDeals />}
      {currentView === "Products" && <AdminProducts />}
    </div>
  );
}

const styles = {
  contentWrapper: {
    padding: 20,
  },
};

export default AdminPage;
