import useTypedQuery from "../helpers/useTypedQuery";
import { ProductsData } from "../../interfaces/Products";

const GET_PRODUCTS = `
      query getProducts {
        products {
          id
          name
          productCategory {
            id
            name
          }
          isActive
        }
      }
    `;

const useProduct = () => useTypedQuery<ProductsData, undefined>(GET_PRODUCTS)?.dataFromQuery;

export default useProduct;
