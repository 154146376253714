import { graphql } from "../../codegen/gql";
import { TypedDocumentNode } from "@apollo/client";
import { CompanyContactQuery, QueryCompanyContactInput } from "../../codegen/gql/graphql";
import useGeneratedTypeQuery from "../helpers/useGeneratedTypeQuery";

const GET_COMPANYCONTACT = graphql(`
  query CompanyContact($input: QueryCompanyContactInput) {
    companycontacts(input: $input) {
      data {
        id
        name
        email
        phone
        contactType
        isArchived
        lastContactedAt
        lastContactedBy {
          id
          fullName
          imageUrl
        }
        createdAt
        createdBy {
          id
          fullName
        }
        updatedAt
        updatedBy {
          id
          fullName
        }
        mainBroker {
          id
          brokerName
        }
        secondBroker {
          id
          brokerName
        }
        thirdBroker {
          id
          brokerName
        }
        company {
          id
          companyName
        }
        products {
          id
          name
        }
      }
      totalCount
    }
  }
`) as TypedDocumentNode<CompanyContactQuery, { input: QueryCompanyContactInput }>;

const useCompanyContact = (input: QueryCompanyContactInput) => {
  const { dataFromQuery, refetch, fetchMore } = useGeneratedTypeQuery<CompanyContactQuery, QueryCompanyContactInput>(GET_COMPANYCONTACT, input);

  const loadMore = (newSkip: number) => {
    fetchMore({
      variables: {
        input: {
          ...input,
          skip: newSkip,
          take: input.take,
        },
      },
    });
  };

  return { dataFromQuery, refetch, fetchMore: loadMore };
};

export default useCompanyContact;
