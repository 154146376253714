import { IClient } from "../../../services/utils/interfaces/ClientDisplayTypes";

const CompanyDetails = ({ clientData }: { clientData: IClient | undefined }) => {
  return (
    <div className="bg-[#fbfbfb] rounded-[16px] mt-[48px] p-4 gap-y-2 flex flex-wrap justify-between w-[800px] max-w-full">
      <div className="w-full md:w-1/2 gap-y-2 flex flex-wrap">
        <div className="flex">
          <p className="text-[#AFAFAF] font-medium  max-w-[33.3%] w-[160px]">General email:</p>
          <p className="font-regular max-w-[66.6%] w-[240px]">{clientData?.generalEmail ?? "Unknown"}</p>
        </div>
        <div className="flex">
          <p className="text-[#AFAFAF] font-medium  max-w-[33.3%] w-[160px]">Website:</p>
          <p className="font-regular max-w-[66.6%] w-[240px]">{clientData?.website ?? "Unknown"}</p>
        </div>
        <div className="flex">
          <p className="text-[#AFAFAF] font-medium  max-w-[33.3%] w-[160px]">CoC:</p>
          <p className="font-regular max-w-[66.6%] w-[240px]">{clientData?.CoC ?? "Unknown"}</p>
        </div>
        <div className="flex">
          <p className="text-[#AFAFAF] font-medium  max-w-[33.3%] w-[160px]">VAT No:</p>
          <p className="font-regular max-w-[66.6%] w-[240px]">{clientData?.vatNo ?? "Unknown"}</p>
        </div>
      </div>
      <div className="w-full md:w-1/2 gap-y-2 flex flex-wrap">
        <div className="flex">
          <p className="text-[#AFAFAF] font-medium  max-w-[33.3%] w-[160px]">Street:</p>
          <p className="font-regular max-w-[66.6%] w-[240px]">{clientData?.companyAddress?.street ?? "Unknown"}</p>
        </div>
        <div className="flex">
          <p className="text-[#AFAFAF] font-medium  max-w-[33.3%] w-[160px]">Postcode:</p>
          <p className="font-regular max-w-[66.6%] w-[240px]">{clientData?.companyAddress?.postCode ?? "Unknown"}</p>
        </div>
        <div className="flex">
          <p className="text-[#AFAFAF] font-medium  max-w-[33.3%] w-[160px]">City:</p>
          <p className="font-regular max-w-[66.6%] w-[240px]">{clientData?.companyAddress?.city ?? "Unknown"}</p>
        </div>
        <div className="flex">
          <p className="text-[#AFAFAF] font-medium  max-w-[33.3%] w-[160px]">Country:</p>
          <p className="font-regular max-w-[66.6%] w-[240px]">{clientData?.country?.name ?? "Unknown"}</p>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
