import { Static, Type } from "@sinclair/typebox";

export const ProductCategorySchema = Type.Object({
  name: Type.String({
    minLength: 3,
    errorMessage: {
      minLength: "Product category name is required",
      type: "Product category name must be a string",
    },
  }),
});

export type ProductCategoryFormData = Static<typeof ProductCategorySchema>;
