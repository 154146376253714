import useGeneratedTypeQuery from "../helpers/useGeneratedTypeQuery";
import { graphql } from "../../codegen/gql";
import { ProductFormOptionsQuery } from "../../codegen/gql/graphql";

const GET_PRODUCTFORMOPTIONS = graphql(`
  query productFormOptions($input: QueryExactItemInput!) {
    productcategories {
      id
      name
    }
    exactitems(input: $input) {
      ... on Error {
        errorCode
        message
      }
      ... on ExactItem {
        code
        description
        idExact
      }
    }
  }
`);

const useProductFormOptions = (input: {}) => useGeneratedTypeQuery<ProductFormOptionsQuery, {}>(GET_PRODUCTFORMOPTIONS, input);

export default useProductFormOptions;
