import Modal from "@material-ui/core/Modal";
import { motion } from "framer-motion";
import ClearIcon from "@mui/icons-material/Clear";

function AddBackdropModal({ open, handleClose, children, height, addCloseButton = false }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={false}
      disableEscapeKeyDown={false}
    >
      <div
        className="bg-white rounded-lg outline-none relative"
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "1080px",
          height: height ?? "95%",
          padding: "20px",
          maxHeight: "90vh",
        }}
      >
        {addCloseButton && (
          <div className="transform absolute top-[10px] right-[15px] box-shadow bg-white hover:bg-gray-200 rounded-[50%] z-10">
            <motion.button
              type="button"
              className="w-[32px] h-[32px]"
              initial={{ scale: 1 }}
              onClick={handleClose}
              whileHover={{ scale: 1, rotate: 90 }}
              whileTap={{ scale: 1 }}
            >
              <ClearIcon className="text-dark-gray-filters scale-60" />
            </motion.button>
          </div>
        )}
        {children}
      </div>
    </Modal>
  );
}

export default AddBackdropModal;
