import React, { useState, useEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline, Button, Snackbar } from "@material-ui/core";
import { auth, firestore } from "../../firebase";
import authentication from "../../services/authentication";
import appearance from "../../services/appearance";
import LaunchScreen from "../LaunchScreen";
import Bar from "../Bar/Bar.tsx";
import Router from "../../Router";
import DialogHost from "../DialogHost";
import { ToastContainer } from "react-toastify";
import olyxbase from "../../services/olyxbase";
import { useUser } from "../../typedHooks/useUser";

function App() {
  const { localUser, loading: userContextLoading, updateUser, refreshUser } = useUser();

  const [state, setState] = useState({
    ready: false,
    authReady: false,
    performingAction: false,
    theme: appearance.defaultTheme,
    user: null,
    userData: null,
    roles: [],
    aboutDialog: { open: false },
    signUpDialog: { open: false },
    signInDialog: { open: false },
    settingsDialog: { open: false },
    deleteAccountDialog: { open: false },
    signOutDialog: { open: false },
    snackbar: { autoHideDuration: 0, message: "", open: false },
    config: { cardTimeout1: 5, cardTimeout2: 120, cardTimeout3: 300 },
  });

  const {
    ready,
    authReady,
    performingAction,
    theme,
    user,
    userData,
    roles,
    aboutDialog,
    signUpDialog,
    signInDialog,
    settingsDialog,
    deleteAccountDialog,
    signOutDialog,
    snackbar,
  } = state;

  let onAuthStateChangedObserver = null;
  let userDocumentSnapshotListener = null;

  const resetState = (callback) => {
    setState((prevState) => ({
      ...prevState,
      ready: false,
      authReady: true,
      theme: appearance.defaultTheme,
      user: null,
      userData: null,
      roles: [],
    }));

    updateUser(null);

    if (callback && typeof callback === "function") {
      callback();
    }
  };

  const openDialog = (dialogId, callback) => {
    const dialog = state[dialogId];
    if (!dialog || dialog.open === undefined || null) return;

    setState((prevState) => ({
      ...prevState,
      [dialogId]: { ...dialog, open: true },
    }));

    if (callback) callback();
  };

  const closeDialog = (dialogId, callback) => {
    const dialog = state[dialogId];
    if (!dialog || dialog.open === undefined || null) return;

    setState((prevState) => ({
      ...prevState,
      [dialogId]: { ...dialog, open: false },
    }));

    if (callback) callback();
  };

  const closeAllDialogs = (callback) => {
    setState((prevState) => ({
      ...prevState,
      aboutDialog: { open: false },
      signUpDialog: { open: false },
      signInDialog: { open: false },
      settingsDialog: { open: false },
      deleteAccountDialog: { open: false },
      signOutDialog: { open: false },
    }));

    if (callback) callback();
  };

  const deleteAccount = () => {
    setState((prevState) => ({ ...prevState, performingAction: true }));

    authentication
      .deleteAccount()
      .then(() => {
        closeAllDialogs(() => {
          openSnackbar("Deleted account");
        });
      })
      .catch((reason) => {
        const code = reason.code;
        const message = reason.message;
        switch (code) {
          default:
            openSnackbar(message);
            return;
        }
      })
      .finally(() => {
        setState((prevState) => ({
          ...prevState,
          performingAction: false,
        }));
      });
  };

  const signOut = () => {
    setState((prevState) => ({ ...prevState, performingAction: true }));

    authentication
      .signOut()
      .then(() => {
        closeAllDialogs(() => {
          openSnackbar("Signed out");
        });
      })
      .catch((reason) => {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          default:
            openSnackbar(message);
            return;
        }
      })
      .finally(() => {
        setState((prevState) => ({
          ...prevState,
          performingAction: false,
        }));
      });
  };

  const openSnackbar = (message, autoHideDuration = 2, callback) => {
    setState((prevState) => ({
      ...prevState,
      snackbar: {
        autoHideDuration: 1500 * autoHideDuration,
        message,
        open: true,
      },
    }));

    if (callback && typeof callback === "function") {
      callback();
    }
  };

  const closeSnackbar = (clearMessage = false) => {
    setState((prevState) => ({
      ...prevState,
      snackbar: {
        ...prevState.snackbar,
        message: clearMessage ? "" : prevState.snackbar.message,
        open: false,
      },
    }));
  };

  // Fetch config once
  useEffect(() => {
    olyxbase.getConfig().then((config) => {
      setState((prevState) => ({
        ...prevState,
        config: {
          ...prevState.config,
          cardTimeout1: config.find((id) => id.configKey === "CARD_TIMEOUT_1"),
          cardTimeout2: config.find((id) => id.configKey === "CARD_TIMEOUT_2"),
          cardTimeout3: config.find((id) => id.configKey === "CARD_TIMEOUT_3"),
        },
      }));
    });
  }, []);

  useEffect(() => {
    let userDocumentSnapshotListener = null;

    const initialLocalUser = localUser;
    const initialRefreshUser = refreshUser;

    const onAuthStateChangedObserver = auth.onAuthStateChanged(
      (user) => {
        if (!user || !user.uid) {
          if (userDocumentSnapshotListener) {
            userDocumentSnapshotListener();
          }
          resetState();
          return;
        }

        setState((prevState) => ({
          ...prevState,
          authReady: true,
          user: user,
        }));

        userDocumentSnapshotListener = firestore
          .collection("users")
          .doc(user.uid)
          .onSnapshot(
            () => {
              authentication
                .getRoles()
                .then(async (value) => {
                  if (!initialLocalUser) {
                    try {
                      await initialRefreshUser();
                      setState((prevState) => ({
                        ...prevState,
                        ready: true,
                        userData: {},
                        roles: value || [],
                      }));
                    } catch (error) {
                      console.error("Error refreshing user data:", error);
                      signOut();
                    }
                  } else {
                    setState((prevState) => ({
                      ...prevState,
                      ready: true,
                      userData: {},
                      roles: value || [],
                    }));
                  }
                })
                .catch((reason) => {
                  resetState(() => {
                    openSnackbar(reason.message);
                  });
                });
            },
            (error) => {
              resetState(() => {
                openSnackbar(error.message);
              });
            }
          );
      },
      (error) => {
        console.error("Auth state change error:", error);
        resetState(() => {
          openSnackbar(error.message);
        });
      }
    );

    return () => {
      if (onAuthStateChangedObserver) {
        onAuthStateChangedObserver();
      }
      if (userDocumentSnapshotListener) {
        userDocumentSnapshotListener();
      }
    };
  }, []);

  const showLoading = !authReady || userContextLoading || (!ready && user);
  const showApp = authReady && !userContextLoading && (ready || !user);

  return (
    <MuiThemeProvider theme={theme}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <CssBaseline />
      {showLoading && <LaunchScreen />}
      {showApp && (
        <>
          <Router
            user={user}
            roles={roles}
            config={state.config}
            bar={
              <Bar
                user={user}
                localUser={localUser}
                onSignInClick={() => openDialog("signInDialog")}
                onAboutClick={() => openDialog("aboutDialog")}
                onSignOutClick={() => openDialog("signOutDialog")}
              />
            }
            openSnackbar={openSnackbar}
          />
          <DialogHost
            performingAction={performingAction}
            theme={theme}
            user={user}
            userData={userData}
            openSnackbar={openSnackbar}
            dialogs={{
              aboutDialog: { dialogProps: { open: aboutDialog.open, onClose: () => closeDialog("aboutDialog") } },
              signUpDialog: {
                dialogProps: {
                  open: signUpDialog.open,
                  onClose: (callback) => {
                    closeDialog("signUpDialog");
                    if (callback && typeof callback === "function") {
                      callback();
                    }
                  },
                },
              },
              signInDialog: {
                dialogProps: {
                  open: signInDialog.open,
                  onClose: (callback) => {
                    closeDialog("signInDialog");
                    if (callback && typeof callback === "function") {
                      callback();
                    }
                  },
                },
              },
              settingsDialog: {
                dialogProps: {
                  open: settingsDialog.open,
                  onClose: () => closeDialog("settingsDialog"),
                },
                props: {
                  onDeleteAccountClick: () => openDialog("deleteAccountDialog"),
                },
              },
              deleteAccountDialog: {
                dialogProps: {
                  open: deleteAccountDialog.open,
                  onClose: () => closeDialog("deleteAccountDialog"),
                },
                props: { deleteAccount: deleteAccount },
              },
              signOutDialog: {
                dialogProps: { open: signOutDialog.open, onClose: () => closeDialog("signOutDialog") },
                props: {
                  title: "Sign out?",
                  contentText: "While signed out you are unable to manage your profile and conduct other activities that require you to be signed in.",
                  dismissiveAction: (
                    <Button color="primary" onClick={() => closeDialog("signOutDialog")}>
                      Cancel
                    </Button>
                  ),
                  confirmingAction: (
                    <Button color="primary" disabled={performingAction} variant="contained" onClick={signOut}>
                      Sign Out
                    </Button>
                  ),
                },
              },
            }}
          />
          <Snackbar autoHideDuration={snackbar.autoHideDuration} message={snackbar.message} open={snackbar.open} onClose={closeSnackbar} />
        </>
      )}
    </MuiThemeProvider>
  );
}

export default App;
