import React, { useState } from "react";
import "./AddFormDealNote.css";
import useMutationDealNotes from "../../hooks/useMutationDealNotes";
import { toast } from "react-toastify";
import TextAreaWithMention from "../TextAreaWithMention/TextAreaWithMention";
import useUsers from "../../hooks/useUsers";
import "react-toastify/dist/ReactToastify.css";

const AddFormDealNote = (props) => {
  // props: { initialValues  }

  const [formError, setFormError] = useState(null);

  const { updateDealNotes, loading } = useMutationDealNotes();

  const afterSubmit = () => {
    toast.dismiss();
    toast.success(`Comment submitted.`, { autoClose: 8000 });
  };

  const addNote = (textContent, mentionedUsersList) => {
    if (textContent.length > 1000) {
      setFormError("Note is too long. Please shorten it to 1000 characters or less.");
      return;
    }
    if (textContent.length === 0) {
      setFormError("Note is empty. Please enter a note.");
      return;
    }

    let mutationInputVals = {
      note: textContent,
      dealId: props.initialValues.dealId,
      mentions: mentionedUsersList,
    };

    return updateDealNotes({ variables: { input: [mutationInputVals] } }).then(() => {
      return afterSubmit();
    });
  };

  const { users } = useUsers();

  return (
    <div className="form-deal-note">
      <div container spacing={3} className="form-field">
        <TextAreaWithMention userList={users} addNote={addNote} formError={formError} setFormError={setFormError} loading={loading} />
      </div>
    </div>
  );
};
export default AddFormDealNote;
