import ChevronLeft from "mdi-material-ui/ChevronLeft";
import ChevronRight from "mdi-material-ui/ChevronRight";

type PaginationProps = {
  totalCount: number;
  navigateBack: () => void;
  navigateForward: (pageCursorEnd: number) => void;
  from: number;
  to: number;
};

const Pagination = ({ totalCount, navigateBack, navigateForward, from, to }: PaginationProps) => {
  return (
    <div className="flex justify-between items-center mt-4 w-fit ml-auto font-regular text-[12px]">
      <button aria-label="previous" className="px-3 py-2 disabled:opacity-50" disabled={from === 1} onClick={navigateBack}>
        <ChevronLeft fontSize="small" />
      </button>

      <span>
        {from}-{to} of {totalCount}
      </span>

      <button aria-label="next" className="px-3 py-2 disabled:opacity-50" disabled={to === totalCount} onClick={() => navigateForward(to)}>
        <ChevronRight fontSize="small" />
      </button>
    </div>
  );
};

export default Pagination;
