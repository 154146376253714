import { useEffect, useState } from "react";
import { useActivityHubFilter } from "../../contexts/ActivityHubContexts";
import useDealConfirmationsCount from "../../typedHooks/queries/queryDealConfirmationsCount";
import CommentsCount from "../CommentsCount/CommentsCount";
import ChecklistItemContainer from "./elements/ChecklistItemContainer";
import { addDays } from "date-fns";
import useDealConditionsCount from "../../typedHooks/queries/queryDealConditionsCount";
import { CheckListItemActionStatus, CheckListItemType } from "../../codegen/gql/graphql";
import useCheckListItemsCount from "../../typedHooks/queries/queryCheckListITemsCount";

const ActivityHubChecklist = () => {
  const { selectedUserViewInArray, checklistView, setChecklistView } = useActivityHubFilter();
  const [oneWeekFromNow, setOneWeekFromNow] = useState(addDays(new Date(), 7));

  // TODO: add user id and verify that toBeResolvedAfter and toBeResolvedBefore are correctly implemented
  const { dataFromQuery: pendingCheckListItemsCount, refetch: refetchPendingCheckListItemsCount } = useCheckListItemsCount({
    checkListItemTypes: [CheckListItemType.Clientonboarding, CheckListItemType.Clientkyc],
    status: CheckListItemActionStatus.Todo,
    toBeResolvedAfter: oneWeekFromNow,
    userIds: selectedUserViewInArray,
  });

  const { dataFromQuery: toDoCheckListItemsCount, refetch: refetchToDoCheckListItemsCount } = useCheckListItemsCount({
    checkListItemTypes: [CheckListItemType.Clientonboarding, CheckListItemType.Clientkyc],
    status: CheckListItemActionStatus.Todo,
    toBeResolvedBefore: oneWeekFromNow,
    userIds: selectedUserViewInArray,
  });

  const { dataFromQuery: completedCheckListItemsCount, refetch: refetchCompletedCheckListItemsCount } = useCheckListItemsCount({
    checkListItemTypes: [CheckListItemType.Clientonboarding, CheckListItemType.Clientkyc],
    status: CheckListItemActionStatus.Done,
    userIds: selectedUserViewInArray,
  });

  // TODO: Below queries will be made obsolete when Conditions and Confirmations are included in useCheckListItems
  const { dataFromQuery: openDealConfirmationsCount, refetch: refetchOpen } = useDealConfirmationsCount({
    userIds: selectedUserViewInArray,
    isConfirmed: false,
  });
  const { dataFromQuery: confirmedDealConfirmationsCount, refetch: refetchConfirmed } = useDealConfirmationsCount({
    userIds: selectedUserViewInArray,
    isConfirmed: true,
  });
  const { dataFromQuery: openDealConditionsCount, refetch: refetchOpenConditions } = useDealConditionsCount({
    isConfirmed: false,
    userIds: selectedUserViewInArray,
    toBeResolvedBefore: oneWeekFromNow,
  });
  const { dataFromQuery: closedDealConditionsCount, refetch: refetchClosedConditions } = useDealConditionsCount({
    isConfirmed: true,
    userIds: selectedUserViewInArray,
  });

  const openChecklistTasksCount =
    openDealConfirmationsCount && openDealConditionsCount && toDoCheckListItemsCount
      ? openDealConfirmationsCount.dealconfirmationscount + openDealConditionsCount.dealconditionscount + toDoCheckListItemsCount.checklistitemscount
      : 0;

  const closedChecklistTasksCount =
    confirmedDealConfirmationsCount && closedDealConditionsCount && completedCheckListItemsCount
      ? confirmedDealConfirmationsCount.dealconfirmationscount +
        closedDealConditionsCount.dealconditionscount +
        completedCheckListItemsCount.checklistitemscount
      : 0;

  // TODO: this variable needs to be the sum of open checklist items with toBeResolvedDates far in the future
  const pendingChecklistTasksCount = pendingCheckListItemsCount?.checklistitemscount || 0;

  useEffect(() => {
    refetchOpen();
    refetchConfirmed();
    refetchOpenConditions();
    refetchClosedConditions();
    refetchPendingCheckListItemsCount();
    refetchToDoCheckListItemsCount();
    refetchCompletedCheckListItemsCount();
  }, [
    checklistView,
    selectedUserViewInArray,
    refetchOpen,
    refetchConfirmed,
    refetchOpenConditions,
    refetchClosedConditions,
    refetchPendingCheckListItemsCount,
    refetchToDoCheckListItemsCount,
    refetchCompletedCheckListItemsCount,
  ]);

  return (
    <div className="rounded-2xl bg-white flex flex-wrap w-full xl:w-[calc(33%-16px)] relative min-h-[240px]">
      <aside className="-rotate-90 absolute top-[148px] -left-[28px]">
        <h1 className="bg-[#86CEF3] w-[132px] h-[28px] absolute top-0 whitespace-nowrap rounded-tl-[6px] rounded-tr-[6px] px-[4px] py-[8px] pt-[10px] text-[14px] text-center leading-none text-white">
          Checklist
        </h1>
      </aside>
      <div className="p-4 w-full h-fit relative flex flex-wrap gap-x-[10px]">
        <div className="flex items-end flex-wrap min-h-[44px]">
          <div
            onClick={() => setChecklistView("Todo")}
            className={`border-b-2  flex items-center gap-2 w-fit ml-4 px-1  ${
              checklistView === "Todo" ? "border-primary-blue" : " border-white cursor-pointer"
            }`}
          >
            <h2 className="text-xl">To do</h2>
            <CommentsCount title={"Todo"} count={openChecklistTasksCount}></CommentsCount>
          </div>
          <div
            onClick={() => setChecklistView("Pending")}
            className={`border-b-2  flex items-center gap-2 w-fit ml-4 px-1  ${
              checklistView === "Pending" ? "border-primary-blue" : " border-white cursor-pointer"
            }`}
          >
            <h2 className="text-xl">Pending</h2>
            <CommentsCount title={"Pending"} count={pendingChecklistTasksCount}></CommentsCount>
          </div>
          <div
            onClick={() => setChecklistView("Done")}
            className={`border-b-2  flex items-center gap-2 w-fit ml-4 px-1  ${
              checklistView === "Done" ? "border-primary-blue" : " border-white cursor-pointer"
            }`}
          >
            <h2 className="text-xl">Done</h2>
            <CommentsCount title={"Done"} count={closedChecklistTasksCount}></CommentsCount>
          </div>
        </div>
        <div className="w-full ml-auto p-4 h-[65vh] no-scrollbar overflow-y-auto">
          <ChecklistItemContainer column={checklistView} />
        </div>
      </div>
    </div>
  );
};

export default ActivityHubChecklist;
