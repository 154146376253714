import React from "react";
import CompanyDetails from "./elements/CompanyDetails";
import CompanyDetailsContactsContainer from "./elements/CompanyDetailsContactsContainer";
import CompanyStatuses from "./elements/CompanyStatuses";
import useSingleClient from "../../typedHooks/queries/querySingleClient";
import { useParams } from "react-router-dom";
import { IClient } from "../../services/utils/interfaces/ClientDisplayTypes";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

const CompanyDetailsPage = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { dataFromQuery } = useSingleClient({ id: parseInt(companyId!, 10) });

  const clientData = dataFromQuery?.getclients[0];

  const clientStatuses = {
    onBoardingStatus: clientData?.onBoardingStatus!,
  };
  const clientExactId = clientData?.idExact || null;

  return (
    <div className="root flex gap-2 flex-wrap">
      <Breadcrumbs pageName={clientData?.companyName} />
      <div className="bg-white rounded-2xl p-[24px] w-full flex flex-wrap">
        <h1 className="text-4xl ">{clientData?.companyName}</h1>
        <CompanyStatuses exactId={clientExactId} statuses={clientStatuses} />
        <CompanyDetails clientData={clientData as IClient} />
        <CompanyDetailsContactsContainer clientData={clientData as IClient} />
      </div>
    </div>
  );
};

export default CompanyDetailsPage;
