import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import moment from "moment";
import { useQuery } from "react-query";
import olyxbase from "../../services/olyxbase";
import "./longpricehistorybox.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import helpers from "../../services/helpers";

export default function LongPriceHistoryBox({ productId, initials, openProductInfoModal }) {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { data, refetch } = useQuery(["pricehistory", productId], () => olyxbase.getProductHistory(productId), {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
    refetch();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const goToHistory = () => {
    openProductInfoModal(productId);
  };

  return (
    <>
      <div
        style={{ width: 20, height: 20, display: "table", padding: 0 }}
        variant="outline-light"
        size="sm"
        className="initials disable-selection "
        onClick={handleClick}
      >
        <span style={{ verticalAlign: "middle", fontSize: initials ? "10px" : "23px", fontWeight: "bold" }}>{initials ? initials : "··"}</span>
      </div>

      <Popover open={isOpen} anchorEl={anchorEl} onClose={handleClose}>
        <div style={{ height: 500, overflowY: "scroll", backgroundColor: "#171720", fontFamily: "Gilroy Medium", paddingTop: 20 }}>
          {!data && <CircularProgress color="secondary" style={{ margin: 50 }} />}
          {data && data.length == 0 && <h3 style={{ margin: 50 }}>No Recent Data</h3>}
          {data &&
            data.map((hist, index) => {
              // if(index == 0) return;

              return (
                <div style={{ paddingLeft: 20, paddingRight: 20, width: 300 }} key={index}>
                  <span style={{ color: "#22b0ff", fontSize: 20 }}>{+helpers.roundPriceInt(hist.priceInt, hist.decimals, hist.decimals)}</span>

                  {data[index + 1] && hist.priceInt < data[index + 1].priceInt && (
                    <span style={{ color: "black", backgroundColor: "#e7adad", paddingLeft: 5, paddingRight: 5, marginLeft: 10 }}>
                      {helpers.roundPriceInt(hist.priceInt - data[index + 1].priceInt, hist.decimals, hist.decimals) +
                        " ▼ " +
                        +(((hist.priceInt - data[index + 1].priceInt) / data[index + 1].priceInt) * 100).toFixed(2) +
                        "%"}
                    </span>
                  )}

                  {data[index + 1] && hist.priceInt == data[index + 1].priceInt && (
                    <span style={{ color: "white", paddingLeft: 5, paddingRight: 5, marginLeft: 10 }}>
                      {helpers.roundPriceInt(hist.priceInt - data[index + 1].priceInt, hist.decimals, hist.decimals) +
                        " - " +
                        +(((hist.priceInt - data[index + 1].priceInt) / data[index + 1].priceInt) * 100).toFixed(2) +
                        "%"}
                    </span>
                  )}

                  {data[index + 1] && hist.priceInt > data[index + 1].priceInt && (
                    <span style={{ color: "black", backgroundColor: "#c5f9c3", paddingLeft: 5, paddingRight: 5, marginLeft: 10 }}>
                      {helpers.roundPriceInt(hist.priceInt - data[index + 1].priceInt, hist.decimals, hist.decimals) +
                        " ▲ " +
                        +(((hist.priceInt - data[index + 1].priceInt) / data[index + 1].priceInt) * 100).toFixed(2) +
                        "%"}
                    </span>
                  )}

                  <br />
                  <span style={{ color: "#00ccff", display: "block", marginTop: 10 }}>{hist.fullName}</span>

                  {hist.extraInfo && <span style={{ color: "white", display: "block", marginTop: 20, fontSize: 17 }}>{hist.extraInfo}</span>}

                  <p style={{ display: "inline-block", fontSize: 15 }}>
                    {" "}
                    {moment(hist.timestamp).isSame(new Date(), "day")
                      ? moment(hist.timestamp)
                          .local()
                          .format("HH:mm")
                      : moment(hist.timestamp).isSame(
                          moment()
                            .subtract(1, "days")
                            .startOf("day"),
                          "day"
                        )
                      ? "Yesterday " +
                        moment(hist.timestamp)
                          .local()
                          .format("HH:mm")
                      : moment(hist.timestamp)
                          .local()
                          .format("DD MMM HH:mm")}
                  </p>
                  <hr />
                </div>
              );
            })}
        </div>
        <div className="see-more-link">
          <a href="#" onClick={goToHistory}>
            See More..
          </a>
        </div>
      </Popover>
    </>
  );
}
