import { gql, useMutation } from "@apollo/client";

const INITIATE_CLIENT_KYC = gql`
  mutation initiatekyc($input: InitiateKYCInput!) {
    initiatekyc(input: $input) {
      ... on CheckListItem {
        id
        createdAt
        updatedAt
        checkListItemType
        isCompleted
        client {
          companyName
        }
        deal {
          id
          olyxRef
        }
        currentCheckListItemAction {
          assignedTo {
            fullName
            id
          }
          id
          actionType
          status
          toBeResolvedAt
        }
        updatedBy {
          id
          imageUrl
          fullName
        }
      }
      ... on Error {
        errorCode
        message
      }
    }
  }
`;

const useInitiateClientKyc = () => {
  const [initiateClientKyc] = useMutation(INITIATE_CLIENT_KYC);

  return initiateClientKyc;
};

export default useInitiateClientKyc;
