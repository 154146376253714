import { useState } from "react";
import AddBackdropModal from "./AddBackdropModal";
import ProductCategoryForm from "../ProductCategoryForm";
import EditIcon from "@mui/icons-material/Edit";
import { motion } from "framer-motion";

function UpdateProductCategoryModal({ productCategory }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseForm = () => setShowEditModal(false);

  return (
    <>
      <AddBackdropModal open={showEditModal} handleClose={handleCloseForm} height="fit-content" addCloseButton={true}>
        <ProductCategoryForm
          closeAfterSubmit={handleCloseForm}
          initialData={{
            id: parseInt(productCategory.id, 10),
            name: productCategory.name,
          }}
        />
      </AddBackdropModal>
      <motion.button
        onClick={() => setShowEditModal(true)}
        className="p-1 hover:bg-[#35B0FF1A] rounded-full transition-colors"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
      >
        <EditIcon sx={{ color: "#35B0FF" }} className="w-5 h-5" />
      </motion.button>
    </>
  );
}

export default UpdateProductCategoryModal;
