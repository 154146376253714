import { gql, useMutation } from "@apollo/client";
import { CreateProductCategoryInput, UpdateProductCategoryInput } from "../../codegen/gql/graphql";

export const MUTATION_CREATE_PRODUCT_CATEGORY = gql`
  mutation CreateProductCategory($input: CreateProductCategoryInput!) {
    createproductcategory(input: $input) {
      ... on ProductCategory {
        id
        name
      }
      ... on Error {
        message
        errorCode
      }
    }
  }
`;

export const MUTATION_UPDATE_PRODUCT_CATEGORY = gql`
  mutation UpdateProductCategory($input: UpdateProductCategoryInput!) {
    updateproductcategory(input: $input) {
      ... on ProductCategory {
        id
        name
      }
      ... on Error {
        message
        errorCode
      }
    }
  }
`;

export const useProductCategoryMutation = () => {
  const [createProductCategory, createProductCategoryState] = useMutation(MUTATION_CREATE_PRODUCT_CATEGORY, {
    refetchQueries: ["productCategories"],
  });

  const [updateProductCategory, updateProductCategoryState] = useMutation(MUTATION_UPDATE_PRODUCT_CATEGORY, {
    refetchQueries: ["productCategories"],
  });

  const handleCreateProductCategory = async (input: CreateProductCategoryInput) => {
    try {
      const response = await createProductCategory({
        variables: { input },
      });

      const result = response.data?.createProductCategory;

      if (result?.__typename === "Error") {
        throw new Error(result.message);
      }

      return result;
    } catch (error) {
      throw error instanceof Error ? error : new Error("Failed to create product category");
    }
  };

  const handleUpdateProductCategory = async (input: UpdateProductCategoryInput) => {
    try {
      const response = await updateProductCategory({
        variables: { input },
      });

      const result = response.data?.updateProductCategory;

      if (result?.__typename === "Error") {
        throw new Error(result.message);
      }

      return result;
    } catch (error) {
      throw error instanceof Error ? error : new Error("Failed to update product category");
    }
  };

  return {
    createProductCategory: handleCreateProductCategory,
    updateProductCategory: handleUpdateProductCategory,
    isLoading: createProductCategoryState.loading || updateProductCategoryState.loading,
    error: createProductCategoryState.error || updateProductCategoryState.error,
  };
};
