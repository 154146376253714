import { ProductCategoryFormData } from "./ProductCategoryForm.schema";
import { useFormState } from "../../typedHooks/useFormState";
import { useProductCategoryMutation } from "../../typedHooks/mutations/useProductCategory";
import { toast } from "react-toastify";

interface UseProductCategorySubmitProps {
  closeAfterSubmit?: () => void;
  onSubmitSuccess?: () => void;
  initialData?: { id: string } & ProductCategoryFormData;
}

export const useProductCategoryFormSubmit = ({ closeAfterSubmit, onSubmitSuccess, initialData }: UseProductCategorySubmitProps) => {
  const { isLoading, error, startSubmit, setFormError, setFormSuccess } = useFormState();

  const { createProductCategory, updateProductCategory } = useProductCategoryMutation();

  const SUCCESS_MESSAGE = "Product category created successfully";
  const ERROR_MESSAGE = "An error occurred while creating the product category";

  const handleSubmit = async (data: ProductCategoryFormData) => {
    startSubmit();

    try {
      if (initialData?.id) {
        await updateProductCategory({
          id: parseInt(initialData.id, 10),
          name: data.name,
        });
      } else {
        await createProductCategory({
          name: data.name,
        });
      }

      setFormSuccess(SUCCESS_MESSAGE);
      toast.success(SUCCESS_MESSAGE);

      if (onSubmitSuccess) {
        onSubmitSuccess();
      }

      if (closeAfterSubmit) {
        closeAfterSubmit();
      }
    } catch (err) {
      setFormError(err instanceof Error ? err.message : "An unexpected error occurred");
      toast.error(ERROR_MESSAGE);
    }
  };

  return {
    handleSubmit,
    isLoading,
    error,
  };
};
