import { useState } from "react";
import AddBackdropModal from "./AddBackdropModal";
import ProductCategoryForm from "../ProductCategoryForm";
import AddIcon from "@mui/icons-material/Add";
import { motion } from "framer-motion";

function AddProductCategoryModal() {
  const [showAddOrCreateProductModal, setShowAddOrCreateProductModal] = useState(false);
  const handleCloseForm = () => setShowAddOrCreateProductModal(false);

  return (
    <>
      <AddBackdropModal open={showAddOrCreateProductModal} handleClose={handleCloseForm} height="fit-content" addCloseButton={true}>
        <ProductCategoryForm closeAfterSubmit={handleCloseForm} />
      </AddBackdropModal>
      <motion.button
        initial="rest"
        whileHover="hover"
        animate="rest"
        className="rounded ml-4 p-2 text-white underline-offset-4 decoration-1 opacity-90 hover:bg-[#ffffff14] text-sm ease-in-out duration-300 flex items-center gap-2"
        onClick={() => setShowAddOrCreateProductModal(true)}
      >
        <AddIcon />
        CREATE NEW PRODUCT CATEGORY
      </motion.button>
    </>
  );
}

export default AddProductCategoryModal;
