import { Dispatch, SetStateAction } from "react";
import { ContactType } from "../../../codegen/gql/graphql";

const ContactsFilterButtons = ({
  contactTypeFilter,
  setContactTableFilters,
  showArchivedContacts,
  setShowArchivedContacts,
}: {
  contactTypeFilter: ContactType[];
  setContactTableFilters: Dispatch<SetStateAction<ContactType[]>>;
  showArchivedContacts: boolean;
  setShowArchivedContacts: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <>
      <button
        className={`w-40 max-w-[30%] md:max-w-none font-regular leading-none text-[16px] flex justify-start py-2 px-2 rounded-xl outline-none hover:bg-primary-blue hover:bg-opacity-5 
            ${contactTypeFilter.length > 0 && contactTypeFilter[0] === ContactType.Sales ? "bg-[#D8EDF4] border-[#86CEF3]" : "border-transparent"}`}
        onClick={() => {
          setContactTableFilters([ContactType.Sales]);
        }}
      >
        Sales
      </button>
      <button
        className={`w-40 max-w-[30%] md:max-w-none font-regular leading-none text-[16px] flex justify-start py-2 px-2 rounded-xl outline-none hover:bg-primary-blue hover:bg-opacity-5 
            ${contactTypeFilter.length > 0 && contactTypeFilter[0] !== ContactType.Sales ? "bg-[#D8EDF4] border-[#86CEF3]" : "border-transparent"}`}
        onClick={() => {
          setContactTableFilters([ContactType.Compliance, ContactType.Dealconfirmation, ContactType.Invoice, ContactType.Logistics]);
        }}
      >
        Backoffice
      </button>
      <div className="max-w-[30%] md:max-w-none flex items-center justify-start relative ml-auto md:ml-0">
        <div className="absolute left-2 pointer-events-none border-[1px] border-[#d5d5d5] top-calc(50%-6px) w-[12px] h-[12px] rounded-[2px] flex items-center justify-center">
          {showArchivedContacts && <div className="bg-[#22b0ff] w-[8px] h-[8px] rounded-[2px]" />}
        </div>
        <label htmlFor={"showArchivedCheckbox"} className="cursor-pointer flex text-[#7E8085] text-xs items-start pl-4 font-smallText">
          <input
            id={"showArchivedCheckbox"}
            type="checkbox"
            className="cursor-pointer form-checkbox mt-[1px] opacity-0"
            onChange={() => setShowArchivedContacts(!showArchivedContacts)}
          />
          Show archived
        </label>
      </div>
    </>
  );
};

export default ContactsFilterButtons;
