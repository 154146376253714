import { useRef } from "react";
import { useState } from "react";
import useUpdateDeal from "../../hooks/useUpdateDeal";
import AddDealPreliminaryInfoInterface from "./AddDealPreliminaryInfoInterface";
import { useNavigate } from "react-router-dom";
import olyxbase from "../../services/olyxbase";
import { motion } from "framer-motion";
import { CircularProgress } from "@material-ui/core";

const AddDealPreliminaryInfo = (props: AddDealPreliminaryInfoInterface) => {
  const navigate = useNavigate();
  const [updateDeal] = useUpdateDeal();
  const [descriptionNewDeal, setDescriptionNewDeal] = useState("");
  const textInput = useRef<HTMLTextAreaElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const pulseMotion = {
    start: { scale: 1, boxShadow: "0 0 0 0px rgba(34 176 255, 1)", ease: "easeOut", duration: 1, type: "tween" },
    exit: {
      boxShadow: "0 0 0 20px rgba(34 176 255, 0)",
      scale: 1,
      transition: {
        duration: 0.8,
        type: "tween",
        ease: "easeOut",
      },
    },
  };

  return (
    <>
      <div className="w-screen bg-black/20 h-screen	relative">
        <motion.div
          variants={pulseMotion}
          initial="start"
          animate="exit"
          exit="exit"
          className="absolute top-32 left-[8%] md:left-40 bg-white w-10/12 md:w-[19rem] p-5 rounded-md border-2"
        >
          <div className="mx-auto w-11/12">
            <h1 className="mb-2">You closed a new deal, congrats!</h1>
            <label htmlFor="Description"></label>
            <textarea
              ref={textInput}
              className=" no-scrollbar mb-4  mt-3 border border-gray-200 rounded p-1 text-[#4c4c4e] bg-white w-full px-2 focus:outline-none appearance-none"
              onKeyUp={(e) => {
                const scrollHeight = Math.max(50, textInput.current!.scrollHeight);
                textInput.current!.style.height = scrollHeight + "px";
              }}
              id="description"
              name="Description"
              placeholder="Add Description"
              value={descriptionNewDeal}
              onChange={(e) => setDescriptionNewDeal(e.target.value)}
            />
            <div className="flex gap-2">
              <button
                className={`transition-all px-2 py-1 bg-primary-blue text-white rounded opacity-90 flex gap-2 items-center ${
                  isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-sky-600 "
                }`}
                onClick={async () => {
                  if (!isLoading) {
                    setIsLoading(true);
                    try {
                      const deal = await updateDeal({
                        variables: { input: { dealStatusId: 4, newInput: true, dealId: undefined, description: descriptionNewDeal } },
                      });
                      props.callback();
                      olyxbase.updateDiscord({ olyxRef: deal.data.deal.olyxRef, description: descriptionNewDeal });
                      navigate(`/mydeals/${deal.data.deal.id}?openModal=true`);
                    } catch (error) {
                      setIsLoading(false);
                      alert("Was not able to claim deal number, please try again and let the data team know.");
                    }
                  }
                }}
              >
                <span>Claim deal number</span>
                {isLoading && <CircularProgress style={{ height: 11, width: 11, color: "white" }} />}
              </button>
              <button
                className="px-2 py-1 bg-white text-gray-dark rounded opacity-90 hover:text-primary-blue"
                onClick={() => {
                  setDescriptionNewDeal("");
                  setIsLoading(false);
                  props.callback();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default AddDealPreliminaryInfo;
