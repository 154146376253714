import React, { useEffect, useState } from "react";
import Pagination from "../ContactTable/elements/pagination/Pagination";
import useCompanyContact from "../../typedHooks/queries/queryCompanyContact";
import useGenericPagination from "../../typedHooks/helpers/useGenericPagination";
import { OrderByDirection, OrderCompanyContactByField } from "../../codegen/gql/graphql";
import EmptyState from "../EmptyState";
import { ContactType } from "../../codegen/gql/graphql";
import ContactTableFilters from "../ContactTable/elements/ContactTableFilters/ContactTableFilters";
import ContactTableRow from "../ContactTable/ContactTableRow";
import ContactTableHead from "../ContactTable/ContactTableHead";
import helpers from "../../services/helpers";

interface SortConfig {
  key: OrderCompanyContactByField;
  direction: OrderByDirection.Asc | OrderByDirection.Desc;
}

const ContactOverview = () => {
  const take = 14;
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: OrderCompanyContactByField.CompanyName, direction: OrderByDirection.Asc });
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");
  const [brokerIds, setBrokerIds] = useState<number[]>([]);
  const [productIds, setProductIds] = useState<number[]>([]);

  const { skip, navigateForward, navigateBack, from, to } = useGenericPagination(totalCount, take);

  const { dataFromQuery, fetchMore } = useCompanyContact({
    skip,
    take,
    contactTypes: [ContactType.Sales],
    searchText: searchText,
    productIds: productIds,
    brokerIds: brokerIds,
    orderByDirection: sortConfig.direction,
    orderByField: sortConfig.key,
  });

  const companyContactsResponse = dataFromQuery?.companycontacts;
  const companyAndContactData = companyContactsResponse?.data || [];
  const gridColumns = helpers.getCrmGridColumns([ContactType.Sales], false);

  const handleSort = (key: OrderCompanyContactByField) => {
    let direction: OrderByDirection.Asc | OrderByDirection.Desc = OrderByDirection.Asc;
    if (sortConfig.key === key && sortConfig.direction === OrderByDirection.Asc) {
      direction = OrderByDirection.Desc;
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (companyContactsResponse) {
      setTotalCount(companyContactsResponse.totalCount);
    }
  }, [companyContactsResponse]);

  useEffect(() => {
    if (skip >= 0) {
      fetchMore(skip);
    }
  }, [skip, fetchMore]);

  return (
    <>
      <ContactTableFilters searchText={searchText} setSearchText={setSearchText} setBrokerIds={setBrokerIds} setProductIds={setProductIds} />
      <div className="w-full bg-white rounded-[16px] p-4">
        {companyAndContactData.length === 0 && <EmptyState title="No contacts found" />}
        {companyAndContactData.length > 0 && (
          <>
            <div className="w-full overflow-x-auto scrollbar-custom">
              <div className={`min-w-[600px] md:min-w-[1327px]  grid grid-cols-8  font-medium text-[12px] text-[#AFAFAF]   ${gridColumns}`}>
                <ContactTableHead handleSort={handleSort} sortConfig={sortConfig} contactTypeFilter={[ContactType.Sales]} hideCompanyName={false} />
              </div>
              <div className="bg-[#fbfbfb] rounded-[16px] p-4 min-w-[600px] md:min-w-[1327px]">
                {companyAndContactData?.map((row, index) => (
                  <ContactTableRow
                    key={row.id}
                    row={row}
                    isLast={index === companyAndContactData.length - 1}
                    hideCompanyName={false}
                    contactTypeFilter={[ContactType.Sales]}
                  />
                ))}
              </div>
            </div>

            <Pagination totalCount={totalCount} navigateBack={navigateBack} navigateForward={navigateForward} from={from} to={to} />
          </>
        )}
      </div>
    </>
  );
};

export default ContactOverview;
