import { useState } from "react";
import AddBackdropModal from "../../components/Modals/AddBackdropModal";
import CreateOrUpdateProductForm from "../../components/CreateOrUpdateProductForm/CreateOrUpdateProductForm";
import AddIcon from "@mui/icons-material/Add";
import { motion } from "framer-motion";

function AddProductModal({ productCategory }) {
  const [showAddOrCreateProductModal, setShowAddOrCreateProductModal] = useState(false);
  const handleCloseForm = () => setShowAddOrCreateProductModal(false);

  return (
    <>
      <AddBackdropModal open={showAddOrCreateProductModal} handleClose={handleCloseForm} height="fit-content">
        <CreateOrUpdateProductForm
          productCategoryId={productCategory.id}
          closeAfterSubmit={() => {
            handleCloseForm();
          }}
        />
      </AddBackdropModal>
      <motion.button
        initial="rest"
        whileHover="hover"
        animate="rest"
        className={
          "col-span-4 px-2 py-2 rounded w-1/4 text-black underline-offset-4 decoration-1 opacity-90 hover:bg-[#ffffff14] border-[1px] bg-gray-100 text-sm ease-in-out duration-300 flex items-center gap-2"
        }
        onClick={() => setShowAddOrCreateProductModal(true)}
      >
        <AddIcon />
        {`Create new product: ${productCategory.name}`}
      </motion.button>
    </>
  );
}

export default AddProductModal;
