import { useState } from "react";
import AddBackdropModal from "../../../components/Modals/AddBackdropModal";
import CreateOrUpdateProductForm from "../../../components/CreateOrUpdateProductForm/CreateOrUpdateProductForm";

function ProductContainer({ product }) {
  const initialValues = {
    name: product.name,
    productCategoryId: product.productCategory.id,
    id: product.id,
    isActive: product.isActive,
    exactItemIdExact: product?.exactItem?.idExact,
  };

  const [showAddOrCreateProductModal, setShowAddOrCreateProductModal] = useState(false);

  const handleCloseForm = () => setShowAddOrCreateProductModal(false);

  return (
    <>
      <AddBackdropModal open={showAddOrCreateProductModal} handleClose={handleCloseForm} height="fit-content">
        <CreateOrUpdateProductForm
          initialValues={initialValues}
          closeAfterSubmit={() => {
            handleCloseForm();
          }}
        />
      </AddBackdropModal>
      <div className="py-2 px-3 xl:px-6 rounded-md border-[1px] min-h-[48px] border-slate-200 bg-white w-1/4 items-center col-span-12 flex justify-start">
        <div className="flex justify-start gap-y-4 gap-x-4 flex-wrap w-full">
          <div className="flex justify-start items-center w-full">
            <button
              onClick={() => {
                setShowAddOrCreateProductModal(true);
              }}
              className="w-full cursor-pointer overflow-auto text-primary-blue underline-offset-2 underline whitespace-nowrap overflow-ellipsis"
            >
              {product.name}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProductContainer;
