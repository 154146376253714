import CheckIcon from "@mui/icons-material/Check";
import { usePermissions } from "../../typedHooks/usePermissions";
import { useState } from "react";
import olyxbase from "../../services/olyxbase";
import { SyncStatus } from "./SyncStatuses";
import ExactSyncHandler from "./elements/ExactSyncHandler";

const ExactSyncStatusDisplay = ({ idExact, companyId }: { idExact: string | null; companyId: string }) => {
  const [syncStatus, setSyncStatus] = useState<SyncStatus>(idExact ? SyncStatus.SYNCHRONIZED : SyncStatus.NOT_SYNCHRONIZED);
  const { isFinance, isEngineer } = usePermissions();

  const handleSyncClient = async () => {
    setSyncStatus(SyncStatus.PROCESSING);
    try {
      const syncedClientSuccessfully = await olyxbase.syncClient(parseInt(companyId, 10));
      setSyncStatus(syncedClientSuccessfully ? SyncStatus.SYNCHRONIZED : SyncStatus.FAILED);
    } catch {
      setSyncStatus(SyncStatus.FAILED);
    }
  };

  return (
    <div className="h-[24px] flex rounded-[12px] p-2 items-center gap-2">
      {idExact ? (
        <div className="relative flex items-start gap-2">
          <div className="self-center relative p-[3px] bg-[#22B0FF] cursor-default rounded-full w-[16px] h-[16px]"></div>
          <CheckIcon className="absolute top-[3px] left-[3px]" style={{ fontSize: 10, height: "10px", color: "white" }} />
          <p className="text-sm font-regular leading-none">Synchronized</p>
        </div>
      ) : (
        <>
          <p className="text-sm font-regular leading-none">Not yet synchronized</p>
          {(isFinance || isEngineer) && <ExactSyncHandler syncStatus={syncStatus} handleSyncClient={handleSyncClient} />}
        </>
      )}
    </div>
  );
};

export default ExactSyncStatusDisplay;
